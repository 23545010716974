import {
  Box,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import CustomText from "../text/customText";
import Empty from "./Empty";
import star from "../../assets/icons/other/starIco.svg";
import liked from "../../assets/icons/other/liked.svg";
import unliked from "../../assets/icons/other/unliked.svg";
import {
  IconChevronDown,
  IconChevronUp,
  IconStarFilled,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import {
  useAddFavouriteProject,
  useClickAd,
  useClickProject,
  useRemoveFavouriteProject,
} from "../../utils/airdrop.api";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

interface TableProps {
  rows: any;
  columns: any;
  isBtn?: any;
  onClick?: any;
  activeOrder?: any;
  setActiveOrder?: any;
  setIsFavorites?: any;
  isFavorites?: any;
  ads?: any[]; // Array of ads data
}

const CustomTable: React.FC<TableProps> = ({
  rows,
  columns,
  isBtn,
  onClick,
  activeOrder,
  setActiveOrder,
  setIsFavorites,
  isFavorites,
  ads,
}) => {
  const { data: token } = useQuery({
    queryKey: ["getToken"],
    queryFn: async () => {
      const token = localStorage.getItem("token");
      return token;
    },
  });

  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const updateAdClick = useClickAd();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const handleRowClick = (projectId: string) => {
    updateRateClick.mutateAsync(projectId);
    // Navigate to the detail page with the project ID as a parameter
    navigate(`/airdrop-detail/${projectId}`);
  };

  const [fav, setFav] = useState<string[]>([]);

  useEffect(() => {
    // Initialize the fav array based on the rows data
    const initialFav = rows
      .filter((row: any) => row.Like)
      .map((row: any) => row.id);
    setFav(initialFav);
  }, [rows]);

  const addFavouriteProject = useAddFavouriteProject(id ?? "");
  const removeFavouriteProject = useRemoveFavouriteProject(id ?? "");
  const updateRateClick = useClickProject();

  const isFav = (id: string) => {
    return fav.includes(id);
  };

  const handleLikeClick = (projectId: string, isLiked: boolean) => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (projectId) {
      setId(projectId);
      if (isFav(id)) {
        removeFavouriteProject.mutate(id);
        setFav((prev) => prev.filter((id) => id !== projectId));
        console.log("test data after remove", fav);
      } else {
        addFavouriteProject.mutate(id);
        setFav((prev) => [...prev, projectId]);
        console.log("test data after adding", fav);
      }
    }
  };
  useEffect(() => {
    if (ads && ads.length > 0) {
      const interval = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 10000); // 60000 milliseconds = 1 minute
      return () => clearInterval(interval);
    }
  }, [ads]);

  console.log(currentAdIndex);
  return (
    <Box width={"100%"}>
      <Table variant="simple">
        <Thead borderTop={"1px solid #3E414A"}>
          <Tr>
            {columns.map((column: any) => (
              <Th
                p={"30px 0px 14px 0px"}
                key={column}
                justifyContent={"center"}
                align="center"
              >
                {column.name === "Like" ? (
                  <Flex
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={() => {
                      if (isFavorites) {
                        setIsFavorites("");
                      } else {
                        setIsFavorites(!isFavorites);
                      }
                    }}
                  >
                    {isFavorites ? (
                      <IconStarFilled
                        color="#FFD700"
                        size={"20px"}
                        cursor={"pointer"}
                      />
                    ) : (
                      <Image
                        src={star}
                        alt="star icon"
                        height={"20px"}
                        width={"20px"}
                      />
                    )}
                  </Flex>
                ) : column.isChevron ? (
                  // If the column is sortable, show the chevron icon
                  column?.key === activeOrder?.columnName &&
                  activeOrder?.columnOrder === "Asc" ? (
                    <Flex
                      width={"100%"}
                      gap={"8px"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      cursor={"pointer"}
                      onClick={() => {
                        if (activeOrder?.columnOrder === "Asc") {
                          setActiveOrder({
                            columnName: column?.key,
                            columnOrder: "Desc",
                          });
                        } else {
                          setActiveOrder({
                            columnName: column?.key,
                            columnOrder: "Asc",
                          });
                        }
                      }}
                    >
                      <CustomText
                        text={column.name}
                        color={"#FFF"}
                        fontFamily={"Montserrat"}
                        align={"center"}
                        size={"16px"}
                        weight={600}
                        h={"16px"}
                        letterSpacing={"-0.5"}
                      />

                      <IconChevronUp color="#FFF" size={"18px"} />
                    </Flex>
                  ) : (
                    <Flex
                      width={"100%"}
                      gap={"8px"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      cursor={"pointer"}
                      onClick={() => {
                        if (activeOrder?.columnOrder === "Desc") {
                          setActiveOrder({
                            columnName: column?.key,
                            columnOrder: "Asc",
                          });
                        } else {
                          setActiveOrder({
                            columnName: column?.key,
                            columnOrder: "Desc",
                          });
                        }
                      }}
                    >
                      <CustomText
                        text={column.name}
                        color={"#FFF"}
                        fontFamily={"Montserrat"}
                        align={"center"}
                        size={"16px"}
                        weight={600}
                        h={"16px"}
                        letterSpacing={"-0.5"}
                      />

                      <IconChevronDown color="#FFF" size={"18px"} />
                    </Flex>
                  )
                ) : (
                  <Flex
                    width={"100%"}
                    justifyContent={
                      column.name === "PROJECT" ||
                      column?.value === "START_DATE" ||
                      column?.value === "END_DATE"
                        ? "flex-start"
                        : "center"
                    }
                    alignItems={"center"}
                    pl={column.name === "PROJECT" ? "24px" : undefined}
                  >
                    <CustomText
                      text={column.name}
                      color={"#FFF"}
                      fontFamily={"Montserrat"}
                      align={"center"}
                      size={"16px"}
                      weight={600}
                      h={"16px"}
                      letterSpacing={"-0.5"}
                    />
                  </Flex>
                )}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody border="none">
          {rows.map((row: any, rowIndex: number) => (
            <React.Fragment key={rowIndex}>
              <Tr
                cursor={"pointer"}
                _hover={{ bg: "#1E1E1E", borderRadius: "8px" }}
                border="none"
                onClick={() => handleRowClick(row.id)}
              >
                {columns.map((column: any, colIndex: number) => (
                  <Td key={colIndex} border="none" textAlign="center">
                    {column.value === "Like" ? (
                      <Flex
                        minW="20px"
                        justifyContent={"center"}
                        alignItems={"center"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLikeClick(row.id, row.Like);
                        }}
                      >
                        <Image
                          src={isFav(row.id) ? liked : unliked}
                          alt={isFav(row.id) ? "liked" : "unliked"}
                          height={"20px"}
                          width={"20px"}
                          cursor={"pointer"}
                        />
                      </Flex>
                    ) : (
                      <Flex
                        width={"100%"}
                        justifyContent={
                          column.value === "PROJECT" ||
                          column.value === "REWARDS" ||
                          column.value === "REQUIREMENT" ||
                          column.value === "START_DATE" ||
                          column.value === "END_DATE"
                            ? "flex-start"
                            : "center"
                        }
                        alignItems={"center"}
                      >
                        {row[column.value]}
                      </Flex>
                    )}
                  </Td>
                ))}
              </Tr>
              {/* Insert ad after every 5th row */}
              {ads && (rowIndex + 1) % 5 === 0 && (
                <Tr key={`ad-${rowIndex}`} border="none" textAlign="center">
                  <Td
                    colSpan={columns.length}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (ads[currentAdIndex]) {
                        updateAdClick.mutateAsync(ads[currentAdIndex]?._id);
                      }
                    }}
                    h={!ads?.[currentAdIndex]?.imageDesktopUrl ? "62px" : ""}
                    border="none"
                  >
                    {ads[currentAdIndex]?.imageDesktopUrl && (
                      <Image
                        cursor={"pointer"}
                        onClick={() => {
                          if (ads[currentAdIndex]?.adLink) {
                            window.open(ads[currentAdIndex]?.adLink, "_blank");
                          }
                        }}
                        src={ads[currentAdIndex]?.imageDesktopUrl}
                        alt="Ad"
                        mx="auto"
                      />
                    )}
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>

      {rows.length === 0 && (
        <Flex
          my={"140px"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {isBtn ? (
            <Empty btn={true} onClick={onClick} />
          ) : (
            <Empty btn={false} />
          )}
        </Flex>
      )}
    </Box>
  );
};

export default CustomTable;

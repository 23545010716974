import { Avatar, Flex, Image, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import placeholder from "../../../../../assets/icons/social/placeholder.svg";
import FormInput from "../../../../form-inputs-label-select/FormInput";
import { IconArrowRight } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useCreateComment } from "../../../../../utils/comment.api";
import { text } from "stream/consumers";

const AfterLogin = ({ user }: any) => {
  const { projectId } = useParams();
  const createCommentMutation = useCreateComment();

  const [commentText, setCommentText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track loading state

  const handleCommentSubmit = async () => {
    setIsSubmitting(true); // Start loading
    console.log("data to be send for comment:", projectId, commentText);
    try {
      await createCommentMutation.mutateAsync({
        projectId,
        text: commentText,
      });

      setCommentText("");
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex
      width={"100%"}
      p={"12px 8px"}
      gap={"12px"}
      alignItems={"center"}
      borderRadius={"8px"}
      bg={" #1E1E1E"}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.25)"}
    >
      <Avatar
        src={user?.nickname}
        name={user?.nickname}
        width={"36px"}
        height={"36px"}
        bg="#A40854"
      />

      <FormInput
        border={"none"}
        bg={"#4B4B4B"}
        placeholder={"say something..."}
        fontstyle={"none"}
        placeholderWeight={400}
        h={"40px"}
        value={commentText}
        onChange={(e: any) => setCommentText(e.target.value)}
        disabled={isSubmitting}
      />
      {createCommentMutation?.isPending ? (
        <Spinner size="sm" color="#fff" />
      ) : (
        <IconArrowRight
          cursor={"pointer"}
          size={"25px"}
          color="#787878"
          onClick={handleCommentSubmit}
          opacity={isSubmitting ? 0.5 : 1}
          pointerEvents={isSubmitting ? "none" : "auto"}
        />
      )}
    </Flex>
  );
};

export default AfterLogin;

import { Button, Card, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "../../components/general/Tag";
import profile from "../../assets/icons/other/profile.svg";
import like from "../../assets/icons/other/unliked.svg";
import CustomTable from "../../components/customTable/CustomTable";
import { columns } from "./tableData-fav";
import DashboardLayout from "../../layout/dashboard/DashboardLayout";
import DropFavouriteCard from "../../components/general/DropFavouriteCard";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";
import { useNavigate } from "react-router-dom";
import { useGetFavouriteAirdrops } from "../../utils/airdrop.api";
import mapDataToCustomFormat from "../../components/customTable/DropDataMapping";
const MypageFavourite = () => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);

  const navigate = useNavigate();
  const [limit, setLimit] = useState(5);

  const { data: airdropsData, isLoading } = useGetFavouriteAirdrops(limit); // Fetch Active Favourite airdrops data
  const [transformedData, setTransformedData] = useState([]);
  const [cardData, setCardData] = useState([]);

  const [condition, setCondition] = useState(false);
  useEffect(() => {
    // Use effect to transform data
    console.log("data we are getting for fav", airdropsData);
    if (airdropsData) {
      const mappedData = airdropsData?.data?.map((item: any) => {
        const { project } = item;
        return project;
      });
      // console.log("testing:", mappedData);
      console.log("testing:", airdropsData);
      const transformedData = mapDataToCustomFormat(mappedData);
      console.log("testing pass:");
      setTransformedData(transformedData);
      setCardData(airdropsData?.data);
      setCondition(true);
    }
  }, [airdropsData, condition]);

  return (
    <DashboardLayout>
      <Flex
        mt={isWeb ? "40px" : "16px"}
        mb={isWeb ? "64px" : "40px"}
        direction={"column"}
        px={isWeb ? undefined : "16px"}
        gap={"24px"}
        maxWidth={"1240px"}
        width={"100%"}
        alignSelf={"center"}
      >
        {/* tags */}
        <Flex alignSelf={"flex-start"} gap={"12px"} alignItems={"center"}>
          <Tag
            icon={profile}
            bg={"#3E414A"}
            onClick={() => navigate("/mypage")}
          />
          <Tag text={"FAVORITES"} icon={like} />
        </Flex>

        {isLoading ? (
          <Flex justify="center" my="20px">
            <Spinner size="md" color="#fff" />
          </Flex>
        ) : (
          <>
            {isWeb ? (
              condition &&
              transformedData && (
                <>
                  <CustomTable
                    columns={columns}
                    rows={transformedData}
                    isBtn={false}
                  />
                  {transformedData?.length < airdropsData?.totalCount && (
                    <Button onClick={() => setLimit(limit + 5)}>
                      Load More
                    </Button>
                  )}
                </>
              )
            ) : (
              <Flex
                direction={"column"}
                gap={"12px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {cardData &&
                  cardData?.map((item: any, index: number) => (
                    <DropFavouriteCard key={index} data={item.project} />
                  ))}

                {cardData?.length < airdropsData?.totalCount && (
                  <Button onClick={() => setLimit(limit + 5)}>Load More</Button>
                )}
              </Flex>
            )}
          </>
        )}
      </Flex>
    </DashboardLayout>
  );
};

export default MypageFavourite;

import React, { useState } from "react";
import AuthLayout from "../../../layout/AuthLayout";
import { Box, Flex, useMediaQuery, useToast } from "@chakra-ui/react";
import BackBtn from "../../../components/buttons/BackBtn";
import AuthHeading from "../../../components/auth/AuthHeading";
import FormLabel from "../../../components/form-inputs-label-select/FormLabel";
import FormInput from "../../../components/form-inputs-label-select/FormInput";
import MainBtn from "../../../components/buttons/MainBtn";
import { useNavigate } from "react-router-dom";
import { breakpoint } from "../../../components/breakpoint-config/Breakpoint";
import { useLogin } from "../../../utils/auth.api";

const Login = () => {
  const toast = useToast();
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan350] = useMediaQuery("(max-width: 350px)");
  const navigate = useNavigate();

  // State for email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Call the useLogin hook
  const loginMutation = useLogin();

  // Function to handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        toast({
          title: "Error",
          description: "Please fill in all fields",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await loginMutation
          .mutateAsync({ email, password })
          .then((result) => {
            navigate("/");
          })
          .catch((err) => {
            toast({
              title: "Error",
              description: err?.message || "Something went wrong!",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      }
    } catch (error: any) {
      // Handle error
      console.error("Login failed:", error);
      toast({
        title: "Error",
        description: error?.message || "Something went wrong!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
        width={"100%"}
      >
        {!isWeb && (
          <Box position={"absolute"} top={"24px"} left={"16px"}>
            <BackBtn onClick={() => navigate("/login")} />
          </Box>
        )}
        {isWeb && <BackBtn onClick={() => navigate("/login")} />}
        <AuthHeading title={"LOG IN"} />
        {/* inputs */}
        <Flex direction={"column"} gap={"16px"} width={"100%"}>
          <Box>
            <FormLabel label={"Email"} />
            <FormInput
              type={"email"}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </Box>

          <Box>
            <FormLabel label={"Password"} />
            <FormInput
              type={"password"}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </Box>
        </Flex>

        <MainBtn
          width={isWeb ? "390px" : isLessThan350 ? "290px" : "320px"}
          text={"LOG IN"}
          bg
          onClick={(e: any) => {
            if (!loginMutation?.isPending) {
              handleSubmit(e);
            }
          }}
          isLoading={loginMutation?.isPending}
        />

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: isWeb ? "16px" : "14px",
            fontWeight: 700,
            lineHeight: "100%",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          I forgot my password
        </p>

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: isLessThan350 ? "15px" : "16px",

            fontWeight: 400,
            lineHeight: "16px",
            alignSelf: "center",
          }}
        >
          Don’t you have an account?
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: isLessThan350 ? "15px" : "16px",

              fontWeight: 700,
              lineHeight: "100%",
              textDecoration: "underline",
              cursor: "pointer",
              alignSelf: "center",
            }}
            onClick={() => navigate("/signup")}
          >
            {" "}
            Sign up
          </span>{" "}
        </p>
      </Flex>
    </AuthLayout>
  );
};

export default Login;

import React, { useState } from "react";
import AuthLayout from "../../../layout/AuthLayout";
import { Box, Flex } from "@chakra-ui/react";
import BackBtn from "../../../components/buttons/BackBtn";
import { useNavigate } from "react-router-dom";
import AuthHeading from "../../../components/auth/AuthHeading";
import CustomText from "../../../components/text/customText";
import FormLabel from "../../../components/form-inputs-label-select/FormLabel";
import FormInput from "../../../components/form-inputs-label-select/FormInput";
import MainBtn from "../../../components/buttons/MainBtn";
import { useDeleteProfile, useRemoveAccount } from "../../../utils/auth.api";

const DeleteConfirmation = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const removeAccountMutation = useDeleteProfile();

  const handleDeleteAccount = async () => {
    try {
      await removeAccountMutation.mutateAsync({ password });
      navigate("/account-delete-success");
    } catch (error) {
      console.error("Error deleting account:", error);
    } 
  };

  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
        width={"100%"}
      >
        <Box position={"absolute"} top={"24px"} left={"16px"}>
          <BackBtn onClick={() => navigate("/mypage")} />
        </Box>

        <AuthHeading title={"Delete an Account"} />

        <CustomText
          width={"252px"}
          text={
            " To delete your account, please write down your password to confrim."
          }
          color={"#FFF"}
          fontFamily={"Montserrat"}
          align={"center"}
          size={"15px"}
          weight={400}
          h={"22.4px"}
          letterSpacing={"-0.5"}
        />

        <Box width={"100%"}>
          <FormLabel label={"Password"} />
          <FormInput
            type={"password"}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </Box>

        <Box position={"absolute"} bottom={"24px"}>
          <MainBtn
            padding={"13px 13px"}
            text={"CONFIRM AND DELETE"}
            bg
            isLoading={removeAccountMutation?.isPending}
            disabled={removeAccountMutation?.isPending}
            onClick={handleDeleteAccount}
          />
        </Box>
      </Flex>
    </AuthLayout>
  );
};

export default DeleteConfirmation;

import React, { useState } from "react";
import fbIcon from "../../../assets/icons/social/facebook.svg";
import appleIcon from "../../../assets/icons/social/apple.svg";
import gooogleIcon from "../../../assets/icons/social/google.svg";
import mailIcon from "../../../assets/icons/social/mail.svg";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import { Flex, Spinner, useToast } from "@chakra-ui/react";
import AuthHeading from "../../../components/auth/AuthHeading";
import AuthOption from "../../../components/auth/AuthOption";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import {
  useAppleLogin,
  useFacebookLogin,
  useGoogleLogin,
} from "../../../utils/auth.api";

const SignupBy = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const loginWithGoogleMutation = useGoogleLogin();
  const loginWithFacebookMutation = useFacebookLogin();
  const loginWithAppleMutation = useAppleLogin();
  const auth = getAuth();
  const providerGoogle = new GoogleAuthProvider();
  const providerFacebook = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  const [loading, setLoading] = useState({
    google: false,
    facebook: false,
    apple: false,
  });

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, providerGoogle)
      .then(async (result) => {
        const user: any = result.user;

        // console.log(user);

        const formData = {
          token: user.accessToken,
        };
        setLoading({
          ...loading,
          google: true,
        });
        await loginWithGoogleMutation?.mutateAsync(formData);
        toast({
          title: "Email Verified",
          description: "You have successfully verified your email",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        sessionStorage.clear();
      })
      .catch((error) => {
        toast({
          title: error?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading({
          ...loading,
          google: false,
        });
      })
      .finally(() => {
        setLoading({
          ...loading,
          google: false,
        });
      });
  };

  const handleFacebookSignIn = () => {
    setLoading({
      ...loading,
      facebook: true,
    });
    signInWithPopup(auth, providerFacebook)
      .then(async (result) => {
        const user: any = result.user;

        const formData: any = {
          token: await user.accessToken,
        };

        console.log(formData);

        await loginWithFacebookMutation.mutateAsync(formData);
        toast({
          title: "Facebook Verified",
          description: "You have successfully verified your facebook",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        sessionStorage.clear();
        setLoading({
          ...loading,
          facebook: false,
        });
      })

      .catch((error) => {
        toast({
          title: error?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading({
          ...loading,
          facebook: false,
        });
      });
  };

  const handleAppleSignIn = () => {
    setLoading({
      ...loading,
      apple: true,
    });
    signInWithPopup(auth, appleProvider)
      .then(async (result) => {
        const user: any = result.user;

        console.log("Apple data", user);

        const formData = {
          token: user.accessToken,
        };
        await loginWithAppleMutation?.mutateAsync(formData);
        toast({
          title: "Apple Verified",
          description: "You have successfully verified your Apple account",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        sessionStorage.clear();
        setLoading({
          ...loading,
          apple: false,
        });
      })
      .catch((error) => {
        toast({
          title: error?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading({
          ...loading,
          apple: false,
        });
      });
  };

  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
      >
        <AuthHeading title="Create an account" />
        <Flex
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"16px"}
          width={"100%"}
        >
          {loading?.google ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={`Continue with Google`}
              icon={gooogleIcon}
              /// isGoogle
              onClick={handleGoogleSignIn}
            />
          )}
          {loading?.facebook ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={"Continue with Facebook"}
              icon={fbIcon}
              onClick={handleFacebookSignIn}
            />
          )}
          {loading?.apple ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={"Continue with Apple"}
              icon={appleIcon}
              onClick={handleAppleSignIn}
            />
          )}
          <AuthOption
            text={"Continue with email"}
            icon={mailIcon}
            onClick={() => navigate("/signup/email")}
          />
        </Flex>

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "22.4px",
            textAlign: "center",
          }}
        >
          <span>By continuing,</span> <br />
          <span>
            you agree to Blockvestor’s{" "}
            <span
              style={{
                color: "#FFF",
                fontFamily: "Montserrat",
                fontSize: "15px",
                fontWeight: 700,
                lineHeight: "140%",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Terms of Use
            </span>{" "}
            and
          </span>{" "}
          <br />
          <span>
            <span
              style={{
                color: "#FFF",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "100%",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Privacy Policy.
            </span>{" "}
          </span>{" "}
        </p>

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "16px",
          }}
        >
          Already have an account?
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            {" "}
            Log in
          </span>{" "}
        </p>
      </Flex>
    </AuthLayout>
  );
};

export default SignupBy;

import { Box, Flex, Image, Select, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../text/customText";
import appStore from "../../../assets/icons/social/appStore.svg";
import googlePlay from "../../../assets/icons/social/googlePlay.svg";
import logo from "../../../assets/logo/main.svg";
import { breakpoint } from "../../breakpoint-config/Breakpoint";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const Footer = () => {
  const { data: token } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan370] = useMediaQuery("(max-width: 370px)");

  const navigate = useNavigate();

  return (
    <Box width={"100%"} bg={"#1E1E1E"}>
      <Flex
        bg={"#1E1E1E"}
        boxShadow={" 0px -16px 4px 0px rgba(0, 0, 0, 0.25)"}
        maxWidth={"1440px"}
        width={"100%"}
        maxHeight={isWeb ? "160px" : "360px"}
        height={"100%"}
        p={isWeb ? "20px 100px" : "32px"}
        direction={"column"}
        gap={isWeb ? "8px" : "24px"}
        borderTop={"2px solid #000"}
        mx={"auto"}
      >
        {/* col 1 */}
        <Flex
          justifyContent={isWeb ? "space-between" : "center"}
          gap={isWeb ? undefined : "24px"}
          pb={isWeb ? "8px" : "24px"}
          px={isWeb ? "8px" : "0px"}
          borderBottom={"1px solid rgba(255, 255, 255, 0.60)"}
          alignItems={"center"}
          direction={isWeb ? "row" : "column"}
          width={"100%"}
        >
          {/* col-1 row-2  in Mobile view */}
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            display={isWeb ? "none" : "flex"}
          >
            <Image
              alt="onAppStore"
              src={appStore}
              width={isLessThan370 ? "118px" : "135px"}
              height={"40px"}
            />

            <Image
              alt="onGooglePlay"
              src={googlePlay}
              width={isLessThan370 ? "118px" : "135px"}
              height={"40px"}
            />
          </Flex>

          {/* col-1 row-1 */}
          <Flex
            direction={isWeb ? "column" : "row"}
            gap={isWeb ? "8px" : undefined}
            justifyContent={isWeb ? "center" : "space-between"}
            width={isWeb ? undefined : "100%"}
          >
            <Flex
              gap={isWeb ? "32px" : "12px"}
              alignItems={isWeb ? "center" : "flex-start"}
              direction={isWeb ? "row" : "column"}
            >
              <Box width={"115px"}>
                <CustomText
                  text={"LEGAL"}
                  fontFamily={"Montserrat"}
                  size={"16px"}
                  weight={700}
                  h={"25.6px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                  cursor={"pointer"}
                  onClick={() => {
                    window.open("https://blockvestor.net/legal", "_blank");
                  }}
                />
              </Box>

              <Box width={"92px"}>
                <CustomText
                  text={"Terms of Use"}
                  fontFamily={"Montserrat"}
                  size={"14px"}
                  weight={400}
                  h={"22.4px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                  cursor={"pointer"}
                  onClick={() => {
                    window.open(
                      "https://blockvestor.net/terms-of-use",
                      "_blank"
                    );
                  }}
                />
              </Box>
              <Box width={"92px"}>
                <CustomText
                  text={"Privacy Policy"}
                  fontFamily={"Montserrat"}
                  size={"14px"}
                  weight={400}
                  h={"22.4px"}
                  letterSpacing={"-0.5px"}
                  cursor={"pointer"}
                  color={"#FFF"}
                  onClick={() => {
                    window.open(
                      "https://blockvestor.net/privacy-policy",
                      "_blank"
                    );
                  }}
                />
              </Box>
            </Flex>
            <Flex
              gap={isWeb ? "32px" : "12px"}
              alignItems={isWeb ? "center" : "flex-start"}
              direction={isWeb ? "row" : "column"}
            >
              <Box width={isWeb ? "115px" : isLessThan370 ? "118px" : "135px"}>
                <CustomText
                  text={"SUPPORT"}
                  fontFamily={"Montserrat"}
                  size={"16px"}
                  weight={700}
                  h={"25.6px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                />
              </Box>

              <Box width={"92px"}>
                <CustomText
                  text={"Contact"}
                  fontFamily={"Montserrat"}
                  size={"14px"}
                  weight={400}
                  h={"22.4px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                  onClick={() =>
                    token ? navigate("/contact") : navigate("/login")
                  }
                  cursor={"pointer"}
                />
              </Box>
            </Flex>
          </Flex>
          {/* col-1 row-2  in web */}
          <Flex
            alignItems={"center"}
            gap={"24px"}
            display={isWeb ? "flex" : "none"}
          >
            <Image
              alt="onAppStore"
              src={appStore}
              width={"135px"}
              height={"40px"}
            />

            <Image
              alt="onGooglePlay"
              src={googlePlay}
              width={"135px"}
              height={"40px"}
            />
          </Flex>
        </Flex>
        {/* col 2 */}
        <Flex
          justifyContent={isWeb ? "space-between" : "flex-start"}
          px={isWeb ? "8px" : "0px"}
          alignItems={isWeb ? "center" : "flex-start"}
          direction={isWeb ? "row" : "column"}
          gap={isWeb ? "0px" : "16px"}
        >
          <Flex
            alignItems={isWeb ? "center" : "flex-start"}
            gap={isWeb ? "16px" : "8px"}
            direction={isWeb ? "row" : "column"}
          >
            <Image alt="logo" src={logo} width={"95px"} height={"24px"} />

            <CustomText
              text={" Copyright ©  2024 Blockvestors All rights reserved."}
              fontFamily={"Montserrat"}
              size={"12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5px"}
              color={"#FFF"}
              align={"start"}
            />
          </Flex>

          {/* <Select
            width={"85px"}
            height={"32px"}
            // placeholder="Eng"
            borderRadius={"8px"}
            border={"1px solid #FFF"}
            color="#FFF"
          >
            <option value="eng" style={{ background: "#000" }}>
              Eng
            </option>
            <option value="esp" style={{ background: "#000" }}>
              Esp
            </option>
            <option value="fr" style={{ background: "#000" }}>
              Fr
            </option>
            <option value="de" style={{ background: "#000" }}>
              De
            </option>
          </Select> */}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;

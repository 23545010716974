import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import Status from "../../components/drops/dropStatus/Status";
import Stats from "../../components/drops/stats/Stats";
import CustomTable from "../../components/customTable/CustomTable";
import { columns } from "./tableData";
import DashboardLayout from "../../layout/dashboard/DashboardLayout";
import DropCard from "../../components/drops/drop/DropCard";
import Empty from "../../components/customTable/Empty";
import { useClickAd, useGetAirdrops } from "../../utils/airdrop.api";
import mapDataToCustomFormat from "../../components/customTable/DropDataMapping";
import { useLogVisit, usePageVisit } from "../../utils/auth.api";
import { useGetAds } from "../../utils/ad.api";
import toast from "react-hot-toast";

const AirDrop = () => {
  const [isWeb] = useMediaQuery("(min-width: 1240px)");
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [isFavorites, setIsFavorites] = useState("");
  const [activeOrder, setActiveOrder] = useState({
    columnName: "startDate",
    columnOrder: "Desc",
  }); // State to track active order [latest, oldest

  const {
    data: airdropsData,
    isLoading,
    refetch,
  } = useGetAirdrops(
    limit,
    0,
    searchQuery,
    status,
    activeOrder.columnName,
    activeOrder.columnOrder,
    isFavorites
  ); // Fetch Active airdrops data

  const { data: ads } = useGetAds(limit, 0);
  console.log(ads);

  const [transformedData, setTransformedData] = useState([]);
  const [cardData, setCardData] = useState(airdropsData?.data);
  const updateAdClick = useClickAd();
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  const [activeTab, setActiveTab] = useState<string | null>(null); // State to track active tab

  const logVisitMutation: any = useLogVisit();
  const pageVisitMutation: any = usePageVisit();

  useEffect(() => {
    const isVisited = sessionStorage.getItem("isVisited");
    const browserDetails = localStorage.getItem("browserDetails");
    // Call the logVisitMutation function
    if (!isVisited && !browserDetails) {
      logVisitMutation
        .mutateAsync()
        .then((data: any) => {
          console.log("Visit logged successfully:", data);
          sessionStorage.setItem("isVisited", "isVisited");
          if (data?.browserDetails) {
            localStorage.setItem("browserDetails", data.browserDetails);
          }
        })
        .catch((error: any) => {
          toast.error("Error logging visit:", error);
        });
    }
    pageVisitMutation
      .mutateAsync()
      .then((data: any) => {
        console.log("pageVisited", data);
      })
      .catch((error: any) => {
        toast.error("Error logging visit:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBack = () => {
    setSearchQuery("");
    setStatus("");
    refetch();

    setActiveTab("");
  };
  useEffect(() => {
    // Use effect to transform data
    if (airdropsData) {
      console.log("testing airdrop:", airdropsData);
      const transformedData = mapDataToCustomFormat(airdropsData?.data);

      setTransformedData(transformedData);
      setCardData(airdropsData?.data);
    }
  }, [airdropsData]);
  // console.log("data for card", cardData);

  const handleTab = (value: any) => {
    setStatus(value);
    setActiveTab(value);
  };

  useEffect(() => {
    if (ads && ads.length > 0) {
      const interval = setInterval(() => {
        setCurrentAdIndex((prevIndex: any) => (prevIndex + 1) % ads.length);
      }, 10000); // 60000 milliseconds = 1 minute
      console.log("Interval set:", interval);
      return () => clearInterval(interval);
    }
  }, [ads]);

  return (
    <>
      <DashboardLayout>
        {/* in WEB DESIGN */}
        <Flex
          display={isWeb ? "flex" : "none"}
          mb={"64px"}
          direction={"column"}
          gap={"24px"}
          maxWidth={"1240px"}
          width={"100%"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <Stats activeTab={activeTab} handleTab={handleTab} />
          <Status
            activeTab={activeTab}
            handleTab={handleTab}
            inputValue={searchQuery}
            setInputValue={setSearchQuery}
          />

          {isLoading ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <>
              <CustomTable
                columns={columns}
                rows={transformedData ? transformedData : []}
                isBtn={true}
                onClick={handleBack}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                setIsFavorites={setIsFavorites}
                isFavorites={isFavorites}
                ads={ads}
              />
              {airdropsData?.totalCount > Number(limit) && (
                <Button onClick={() => setLimit(limit + 5)}>Load More</Button>
              )}
            </>
          )}
        </Flex>

        {/* in mobile view */}
        <Flex
          display={isWeb ? "none" : "flex"}
          mt={isWeb ? "40px" : "10px"}
          mb={isWeb ? "64px" : "40px"}
          direction={"column"}
          gap={"16px"}
          maxWidth={"1240px"}
          width={"100%"}
          alignSelf={"center"}
        >
          <Box px={isWeb ? undefined : "16px"}>
            <Status inputValue={searchQuery} setInputValue={setSearchQuery} />
          </Box>
          <Stats activeTab={activeTab} handleTab={handleTab} />

          {/* filters section */}
          {/* <Flex gap={"8px"} alignItems={"center"} px={"16px"}>
            <Flex
              borderRadius={"100px"}
              bg={"#3E414A"}
              height={"32px"}
              // width={"25px"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"10px"}
              p={"8px"}
              cursor={"pointer"}
           
            >
              <Image alt="icon" src={filterIco} sizes="16px" />
              <CustomText
                text={"LATEST"}
                color={"#FFF"}
                fontFamily={"Montserrat"}
                align={"center"}
                size={"16px"}
                weight={500}
                h={"16px"}
                letterSpacing={"-0.5"}
              />
            </Flex>

            <Flex
              borderRadius={"100px"}
              bg={"#3E414A"}
              height={"32px"}
              // width={"25px"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"6px"}
              p={"8px"}
              cursor={"pointer"}
            >
              <CustomText
                text={"TYPE"}
                color={"#FFF"}
                fontFamily={"Montserrat"}
                align={"center"}
                size={"16px"}
                weight={500}
                h={"16px"}
                letterSpacing={"-0.5"}
              />

              <IconChevronDown color="#fff" size={"18px"} />
            </Flex>

            <Flex
              borderRadius={"100px"}
              bg={"#3E414A"}
              height={"32px"}
              justifyContent={"center"}
              alignItems={"center"}
              p={"8px"}
              cursor={"pointer"}
            >
             
              <Image alt="icon" src={unliked} width={"16px"} height={"16px"} />
            </Flex>
          </Flex> */}

          {cardData && (
            <Flex
              px={isWeb ? undefined : "16px"}
              direction={"column"}
              gap={"12px"}
            >
              {isLoading ? (
                <Flex justify="center">
                  <Spinner size="md" color="#fff" />
                </Flex>
              ) : (
                <>
                  {cardData?.map((item: any, index: any) => (
                    <>
                      <DropCard
                        key={index}
                        data={item}
                        status="ONGOING"
                        color="#DD1B78"
                      />
                      {(index + 1) % 5 === 0 && (
                        <>
                          <Box
                            onClick={(e) => {
                              e.stopPropagation();
                              if (ads?.[currentAdIndex]) {
                                updateAdClick.mutateAsync(
                                  ads?.[currentAdIndex]?._id
                                );
                              }
                            }}
                            h={
                              !ads?.[currentAdIndex]?.imageMobileUrl
                                ? "56px"
                                : ""
                            }
                          >
                            {ads?.[currentAdIndex]?.imageMobileUrl && (
                              <Image
                                cursor={"pointer"}
                                onClick={() => {
                                  if (ads?.[currentAdIndex]?.adLink) {
                                    window.open(
                                      ads?.[currentAdIndex]?.adLink,
                                      "_blank"
                                    );
                                  }
                                }}
                                src={ads?.[currentAdIndex]?.imageMobileUrl}
                                alt="Ad"
                                mx="auto"
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </>
                  ))}

                  {airdropsData?.totalCount > Number(limit) && (
                    <Button onClick={() => setLimit(limit + 5)}>
                      Load More
                    </Button>
                  )}

                  {airdropsData?.totalCount < 1 && (
                    <Flex
                      px={isWeb ? undefined : "16px"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      my={"116px"}
                    >
                      <Empty btn={true} onClick={handleBack} />
                    </Flex>
                  )}
                </>
              )}
              {/* <DropCard data={cardData} status={"ONGOING"} color={"#DD1B78"} /> */}
              {/* <DropCard isLike />
              <DropCard isLike status={"ONGOING"} color={"#DD1B78"} />
              <DropCard status={"OPEN SOON"} color={"#FBFF42"} />
              <DropCard status={"CLOSED"} color={"#FFF"} />
              <DropCard isLike status={"OPEN SOON"} color={"#FBFF42"} /> */}
            </Flex>
          )}
        </Flex>
      </DashboardLayout>
    </>
  );
};

export default AirDrop;

import { Box, Flex, Image, Spinner, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import bg from "../../../assets/bg/dashboardWebBG.png";
import logo from "../../../assets/logo/main.svg";
import Tabs from "./Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { News } from "../../../assets/icons/dashboard-tabs/news";
import { NewsActive } from "../../../assets/icons/dashboard-tabs/news-active";
import { Airdrop } from "../../../assets/icons/dashboard-tabs/airdrop";
import { AirdropActive } from "../../../assets/icons/dashboard-tabs/airdropActive";
import { Kol } from "../../../assets/icons/dashboard-tabs/kol";
import { KolActive } from "../../../assets/icons/dashboard-tabs/kol-active";
import { Login } from "../../../assets/icons/dashboard-tabs/login";
import { LoginActive } from "../../../assets/icons/dashboard-tabs/login-active";
import { Contact } from "../../../assets/icons/dashboard-tabs/contact";
import { ContactActive } from "../../../assets/icons/dashboard-tabs/contactActive";

const Navbar = () => {
  const navigate = useNavigate();
  let location = useLocation();
  ///console.log(location.pathname);

  const [isWeb] = useMediaQuery("(min-width: 1240px)");

  const { data: token, isPending } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  const [activeTab, setActiveTab] = useState(location.pathname);

  // const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());

  useEffect(() => {
    setActiveTab(location.pathname);
    // setIsLoggedIn(isUserLoggedIn());
  }, [location.pathname]);

  const handleTabClick = (tabText: string) => {
    const lowercaseTabText = tabText.toLowerCase();
    if (lowercaseTabText === "airdrop") {
      navigate("/");
    } else if (lowercaseTabText === "log in") {
      navigate("/login");
    } else {
      navigate(`/${lowercaseTabText}`);
    }
  };

  const handleDummy = (tabText: string) => {
    const lowercaseTabText = tabText.toLowerCase();
    window.alert("Coming Soon");
  };

  const navigation = [{}];

  return (
    <>
      {isWeb ? (
        <Flex
          bgImage={bg}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          maxWidth={"1540px"}
          width={"100%"}
          maxHeight={"90px"}
          height={"100%"}
          mx={"auto"}
        >
          <Flex
            maxWidth="1240px"
            w="100%"
            mx="auto"
            justifyContent={"space-between"}
            maxHeight={"90px"}
            height={"100%"}
            p={"15px 0px 0px 15px"}
          >
            <Box width={"100%"} mb={"15px"}>
              <Image
                src={logo}
                width={"240px"}
                height={"60px"}
                onClick={() => navigate("/")}
                cursor={"pointer"}
              />
            </Box>

            {isPending ? (
              <Spinner />
            ) : (
              <Flex gap={"26px "} justify="end" width={"100%"}>
                <Tabs
                  text={"AIRDROP"}
                  icon={<Airdrop />}
                  iconActive={<AirdropActive />}
                  isActive={activeTab?.includes("airdrop") || activeTab === "/"}
                  onClick={handleTabClick}
                />
                <Tabs
                  text={"NEWS"}
                  icon={<News />}
                  iconActive={<NewsActive />}
                  isActive={activeTab === "NEWS"}
                  onClick={handleDummy}
                />
                <Tabs
                  text={"KOL "}
                  icon={<Kol />}
                  iconActive={<KolActive />}
                  isActive={activeTab === "KOL "}
                  onClick={handleDummy}
                />
                {token && (
                  <Tabs
                    text={"MyPage"}
                    icon={<Login />}
                    iconActive={<LoginActive />}
                    isActive={activeTab?.includes("page")}
                    // isActive={activeTab === "/mypage"}
                    onClick={handleTabClick}
                  />
                )}
                {!token && (
                  <Tabs
                    text={"LOG IN"}
                    icon={<Login />}
                    iconActive={<LoginActive />}
                    isActive={activeTab === "/login"}
                    onClick={handleTabClick}
                  />
                )}

                <Tabs
                  text={"CONTACT"}
                  icon={<Contact />}
                  iconActive={<ContactActive />}
                  isActive={activeTab === "/contact"}
                  onClick={
                    token
                      ? handleTabClick
                      : () => {
                          navigate("/login");
                        }
                  }
                />
              </Flex>
            )}
          </Flex>
          {/* <Box maxW="400px" width="100%">
              {props?.children}
            </Box> */}
        </Flex>
      ) : (
        <Flex
          bgImage={bg}
          backgroundSize="cover"
          maxWidth={"1240px"}
          width={"100%"}
          maxHeight={"90px"}
          height={"100%"}
          mx={"auto"}
          p={"7.5px 0px 7.5px 0px "}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image
            src={logo}
            width={"140px"}
            height={"35px"}
            onClick={() => navigate("/")}
          />
        </Flex>
      )}
    </>
  );
};

export default Navbar;

import React from "react";

export const KolActive = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 12C11.809 12 14.5 9.309 14.5 6C14.5 2.691 11.809 0 8.5 0C5.191 0 2.5 2.691 2.5 6C2.5 9.309 5.191 12 8.5 12ZM8.5 2C10.706 2 12.5 3.794 12.5 6C12.5 8.206 10.706 10 8.5 10C6.294 10 4.5 8.206 4.5 6C4.5 3.794 6.294 2 8.5 2ZM10.5 15C10.5 15.552 10.052 16 9.5 16C5.64 16 2.5 19.14 2.5 23C2.5 23.552 2.052 24 1.5 24C0.948 24 0.5 23.552 0.5 23C0.5 18.038 4.538 14 9.5 14C10.052 14 10.5 14.448 10.5 15ZM24.144 17.752L21.778 19.679L22.757 22.659C22.891 23.062 22.755 23.506 22.418 23.765C22.081 24.024 21.617 24.042 21.262 23.811L18.508 22.018L15.8 23.83C15.632 23.943 15.438 23.999 15.244 23.999C15.033 23.999 14.822 23.932 14.644 23.799C14.304 23.545 14.163 23.103 14.29 22.698L15.231 19.682L12.854 17.748C12.534 17.477 12.417 17.035 12.562 16.641C12.707 16.247 13.081 15.986 13.5 15.986H16.501L17.563 13.006C17.709 12.615 18.083 12.355 18.5 12.355C18.917 12.355 19.292 12.615 19.437 13.006L20.499 15.986H23.5C23.92 15.986 24.295 16.249 24.439 16.643C24.583 17.037 24.465 17.48 24.144 17.751V17.752Z"
        fill="white"
      />
    </svg>
  );
};

import { Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../text/customText";
import { breakpoint } from "../../../breakpoint-config/Breakpoint";

const CardTabs = ({ isActive, text, onClick, dummy }: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);

  return (
    <Flex
      p={isWeb ? "8px 8px" : isLessThan370 ? "8px 2px" : "8px 4px"}
      borderRadius={"8px 8px 0px 0px"}
      bg={isActive ? "#3E414A" : " #1E1E1E"}
      h={"40px"}
      cursor={dummy ? "default" : "pointer"}
      onClick={onClick}
    >
      <CustomText
        fontFamily={"Montserrat"}
        text={text}
        fontSize={isWeb ? "24px" : isLessThan370 ? "12" : "16px"}
        weight={isActive ? 700 : 400}
        lineHeight={"24px"}
        letterSpacing={"-0.5px"}
        color={isActive ? "#FFF" : "rgba(255, 255, 255, 0.67)"}
      />
    </Flex>
  );
};

export default CardTabs;

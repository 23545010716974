import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import { Box, Flex, useMediaQuery, useToast } from "@chakra-ui/react";
import BackBtn from "../../../components/buttons/BackBtn";
import AuthHeading from "../../../components/auth/AuthHeading";
import FormLabel from "../../../components/form-inputs-label-select/FormLabel";
import FormInput from "../../../components/form-inputs-label-select/FormInput";
import MainBtn from "../../../components/buttons/MainBtn";
import { breakpoint } from "../../../components/breakpoint-config/Breakpoint";
import { useSignUp } from "../../../utils/auth.api";

const Signup = () => {
  const toast = useToast();
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan350] = useMediaQuery("(max-width: 350px)");
  const navigate = useNavigate();

  // State for form input values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nickname, setNickname] = useState("");

  // Call the useSignUp hook
  const signUpMutation = useSignUp();

  // Function to handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!email || !password || !nickname) {
        toast({
          title: "Error",
          description: "Please fill in all fields",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await signUpMutation
          .mutateAsync({ email, password, nickname })
          .then((result) => {
            toast({
              title: "Success",
              description: "Account created successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            sessionStorage.clear();
            navigate("/login");
          })
          .catch((err) => {
            toast({
              title: "Error",
              description: err?.message || "Something went wrong!",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
      }
    } catch (error: any) {
      // Handle error
      toast({
        title: "Error",
        description: error?.message || "Something went wrong!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Signup failed:", error);
    }
  };

  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
        width={"100%"}
      >
        {!isWeb && (
          <Box position={"absolute"} top={"24px"} left={"16px"}>
            <BackBtn onClick={() => navigate("/signup")} />
          </Box>
        )}
        {isWeb && <BackBtn onClick={() => navigate("/signup")} />}
        <AuthHeading title={"Create an account"} />
        {/* inputs */}
        <Flex direction={"column"} gap={"16px"} width={"100%"}>
          <Box>
            <FormLabel label={"Email"} />
            <FormInput
              type={"email"}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </Box>

          <Box>
            <FormLabel label={"Password"} />
            <FormInput
              type={"password"}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </Box>

          <Box>
            <FormLabel label={"Nick Name"} />
            <FormInput
              type={"text"}
              value={nickname}
              onChange={(e: any) => setNickname(e.target.value)}
            />
          </Box>
        </Flex>

        <MainBtn
          width={isWeb ? "390px" : isLessThan350 ? "290px" : "320px"}
          text={"SIGN UP"}
          bg
          onClick={(e: any) => {
            if (!signUpMutation?.isPending) {
              handleSubmit(e);
            }
          }}
          isLoading={signUpMutation?.isPending}
        />

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "22.4px",
            textAlign: "center",
          }}
        >
          By clicking the ‘Sign Up’ button, you are creating a Blockvestors
          account and therefore you agree to Blockvestor’s
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: "15px",
              fontWeight: 700,
              lineHeight: "140%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            Terms of Use
          </span>{" "}
          and
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            Privacy Policy.
          </span>{" "}
        </p>

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "16px",
          }}
        >
          Already have an account?
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "100%",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate("/login/email")}
          >
            {" "}
            Log in
          </span>{" "}
        </p>
      </Flex>
    </AuthLayout>
  );
};

export default Signup;

import React from "react";
import AuthLayout from "../../../layout/AuthLayout";
import { Box, Flex } from "@chakra-ui/react";
import BackBtn from "../../../components/buttons/BackBtn";
import { useNavigate } from "react-router-dom";
import AuthHeading from "../../../components/auth/AuthHeading";
import CustomText from "../../../components/text/customText";
import MainBtn from "../../../components/buttons/MainBtn";

const AccountDeleted = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
        width={"100%"}
      >
        <Box position={"absolute"} top={"24px"} left={"16px"}>
          <BackBtn onClick={() => navigate("/mypage")} />
        </Box>

        <AuthHeading title={"Account Deleted."} />

        <CustomText
          width={"252px"}
          text={
            "Thank you for using Blockvestor.Hope seeing you soon in the Service!"
          }
          color={"#FFF"}
          fontFamily={"Montserrat"}
          align={"center"}
          size={"15px"}
          weight={400}
          h={"22.4px"}
          letterSpacing={"-0.5"}
        />

        <Box position={"absolute"} bottom={"24px"}>
          <MainBtn
            padding={"13px 13px"}
            text={"GO BACK HOME"}
            bg
            onClick={() => navigate("/mypage")}
          />
        </Box>
      </Flex>
    </AuthLayout>
  );
};

export default AccountDeleted;

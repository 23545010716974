import { Flex, Image, Spinner } from "@chakra-ui/react";
import { IconThumbDown, IconThumbUp } from "@tabler/icons-react";
import React from "react";
import CustomText from "../../text/customText";
import editIcon from "../../../assets/icons/other/edit.svg";
import eraserIcon from "../../../assets/icons/other/erase.svg";
import { useCommentDelete } from "../../../utils/comment.api";
import { useRateById } from "../../../utils/airdrop.api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const Toggle = ({ commentModify, commentId, w, rate, projectId }: any) => {
  const { data: token } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  const navigate = useNavigate();

  const commentRemove = useCommentDelete(commentId);
  const rateProject = useRateById(projectId);

  const handleDelete = async () => {
    await commentRemove.mutateAsync(commentId);
  };

  const handleRate = async (rating: number) => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      await rateProject.mutateAsync(rating);
    } catch (error) {
      console.error("Error rating the project:", error);
    }
  };

  return (
    <Flex
      maxW={w}
      width={"100%"}
      p={"12px 8px"}
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      borderRadius={"8px"}
      bg={"#1E1E1E"}
      boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
      gap={"8px"}
    >
      <Flex
        gap={"8px"}
        pb={"8px"}
        alignItems={"center"}
        borderBottom={"1px solid #FFF"}
        width={"100%"}
        justifyContent={"center"}
        onClick={rate ? () => handleRate(1) : commentModify}
      >
        {rate ? (
          <IconThumbUp size={"20px"} color="#FFF" />
        ) : (
          <Image src={editIcon} sizes="20px" alt="icon" />
        )}

        <CustomText
          text={rate ? "LIKE" : "MODIFY"}
          color={"#FFF"}
          fontFamily={"Montserrat"}
          size={rate ? "20px" : "14px"}
          weight={rate ? 500 : 600}
          h={rate ? "20px" : "14px"}
          letterSpacing={"-0.5"}
        />
      </Flex>

      {commentRemove.isPending ? (
        <Spinner />
      ) : (
        <Flex
          gap={"8px"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"center"}
          onClick={rate ? () => handleRate(-1) : handleDelete}
          style={{ cursor: "pointer" }}
        >
          {rate ? (
            <IconThumbDown size={"20px"} color="#FFF" />
          ) : (
            <Image src={eraserIcon} sizes="20px" alt="icon" />
          )}

          <CustomText
            text={rate ? "DISLIKE" : "DELETE"}
            color={"#FFF"}
            fontFamily={"Montserrat"}
            size={rate ? "20px" : "14px"}
            weight={rate ? 500 : 600}
            h={rate ? "20px" : "14px"}
            letterSpacing={"-0.5"}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Toggle;

import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../text/customText";
import Searchbar from "./Searchbar";
import { breakpoint } from "../../breakpoint-config/Breakpoint";
import { IconArrowLeft, IconArrowRight, IconX } from "@tabler/icons-react";

const Status = ({ inputValue, setInputValue, activeTab, handleTab }: any) => {
  const [isWeb] = useMediaQuery("(min-width: 1240px)");

  const [result, setResult] = useState("");

  //isInput will use in mobile view to hide or show input
  const [isInput, setIsInput] = useState(false);

  const handleIsInput = () => {
    setIsInput(!isInput);
  };

  const handleSearchResult = () => {
    setResult("");
  };

  return (
    <Flex
      justifyContent={"space-between"}
      maxWidth={"1240px"}
      width={"100%"}
      alignItems={"center"}
      height={"40px"}
    >
      <Box maxWidth={"85%"}>
        {!isInput && (
          <>
            {result === "" ? (
              <Flex align="center" gap="20px">
                {activeTab && (
                  <IconArrowLeft
                    color="#FFF"
                    size={"24px"}
                    cursor="pointer"
                    onClick={() => {
                      handleTab("");
                    }}
                  />
                )}
                <CustomText
                  text={"AIRDROP STATUS"}
                  fontFamily={"Montserrat"}
                  size={isWeb ? "32px" : "20px"}
                  weight={700}
                  h={isWeb ? "32px" : "20px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                />
              </Flex>
            ) : (
              <p
                style={{
                  color: "#FFF",
                  fontFamily: "Montserrat",
                  fontSize: isWeb ? "24px" : "16px",
                  fontWeight: 300,
                  lineHeight: isWeb ? "24px" : "16px",
                  letterSpacing: "-0.5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Results of{" "}
                <span
                  style={{
                    marginLeft: isWeb ? "4px" : "2px",
                    color: "#FFF",
                    fontFamily: "Montserrat",
                    fontSize: isWeb ? "24px" : "16px",
                    fontWeight: 600,
                    lineHeight: "100%",
                    letterSpacing: "-0.5px",
                  }}
                >
                  {" "}
                  {result}
                </span>{" "}
              </p>
            )}
          </>
        )}
      </Box>
      {result === "" ? (
        <Searchbar
          setResult={setResult}
          handleIsInput={handleIsInput}
          isInput={isInput}
          {...{ inputValue, setInputValue }}
        />
      ) : (
        <IconX color="#FFF" size={"18px"} onClick={handleSearchResult} />
      )}
    </Flex>
  );
};

export default Status;

import { Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tabs from "../navbar/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { News } from "../../../assets/icons/dashboard-tabs/news";
import { NewsActive } from "../../../assets/icons/dashboard-tabs/news-active";
import { Airdrop } from "../../../assets/icons/dashboard-tabs/airdrop";
import { AirdropActive } from "../../../assets/icons/dashboard-tabs/airdropActive";
import { Kol } from "../../../assets/icons/dashboard-tabs/kol";
import { KolActive } from "../../../assets/icons/dashboard-tabs/kol-active";
import { Login } from "../../../assets/icons/dashboard-tabs/login";
import { LoginActive } from "../../../assets/icons/dashboard-tabs/login-active";
import { Contact } from "../../../assets/icons/dashboard-tabs/contact";
import { ContactActive } from "../../../assets/icons/dashboard-tabs/contactActive";
import { useQuery } from "@tanstack/react-query";

const MenuBar = () => {
  const navigate = useNavigate();

  let location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  const [isGreaterthan350] = useMediaQuery(`(min-width: 350px)`);

  const { data: token, isPending } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleTabClick = (tabText: string) => {
    const lowercaseTabText = tabText.toLowerCase();
    if (lowercaseTabText === "airdrop" && !token) {
      navigate("/");
    } else {
      navigate(`/${lowercaseTabText}`);
    }
  };

  const handleDummy = (tabText: string) => {
    const lowercaseTabText = tabText.toLowerCase();
    window.alert("Coming Soon");
  };
  return (
    <Flex
      width={"100%"}
      p={"8px 0px"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={isGreaterthan350 ? "12px" : "5px"}
      bg={"#1E1E1E"}
      boxShadow={"0px -8px 4px 0px rgba(0, 0, 0, 0.25)"}
      position="sticky"
      bottom="0"
    >
      <Tabs
        text={"AIRDROP"}
        icon={<Airdrop />}
        iconActive={<AirdropActive />}
        isActive={activeTab === "/"}
        onClick={handleTabClick}
      />
      <Tabs
        text={"NEWS"}
        icon={<News />}
        iconActive={<NewsActive />}
        isActive={activeTab === "NEWS"}
        onClick={handleDummy}
      />
      <Tabs
        text={"KOL "}
        icon={<Kol />}
        iconActive={<KolActive />}
        isActive={activeTab === "KOL "}
        onClick={handleDummy}
      />

      {token && (
        <Tabs
          text={"MyPage"}
          icon={<Login />}
          iconActive={<LoginActive />}
          isActive={activeTab?.includes("page")}
          // isActive={activeTab === "/mypage"}
          onClick={handleTabClick}
        />
      )}
      {!token && (
        <Tabs
          text={"LOG IN"}
          icon={<Login />}
          iconActive={<LoginActive />}
          isActive={activeTab === "/login"}
          onClick={handleTabClick}
        />
      )}
      <Tabs
        text={"CONTACT"}
        icon={<Contact />}
        iconActive={<ContactActive />}
        isActive={activeTab === "/contact"}
        onClick={
          token
            ? handleTabClick
            : () => {
                navigate("/login");
              }
        }
      />
    </Flex>
  );
};

export default MenuBar;

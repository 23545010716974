import { Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../text/customText";
import { breakpoint } from "../../breakpoint-config/Breakpoint";
const Tabs = ({ icon, iconActive, text, isActive, onClick }: any) => {
  const [isWeb] = useMediaQuery("(min-width: 1240px)");

  const handleClick = () => {
    onClick(text);
  };

  return (
    <Flex
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"4px"}
      width={"100%"}
      height={"100%"}
      maxWidth={isWeb ? "80px" : "55px"}
      maxHeight={isWeb ? "80px" : "48px"}
      cursor={"pointer"}
      borderBottom={isWeb ? (isActive ? "3px solid white" : 0) : undefined}
      onClick={handleClick}
    >
      {isActive
        ? iconActive
        : // <Image alt="icon" src={iconActive} width={"24px"} height={"24px"} />
          icon
          // <Image alt="icon" src={icon} width={"24px"} height={"24px"} />
      }

      <CustomText
        color={isActive ? "#FFF" : "rgba(255, 255, 255, 0.60)"}
        text={text}
        size={"12px"}
        h={"12px"}
        weight={isWeb ? (isActive ? 600 : 400) : isActive ? 600 : 300}
        fontFamily={"Montserrat"}
      />
    </Flex>
  );
};

export default Tabs;

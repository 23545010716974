import {
  Box,
  Flex,
  useMediaQuery,
  Input,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FormInput from "../../components/form-inputs-label-select/FormInput";
import FormLabel from "../../components/form-inputs-label-select/FormLabel";
import FormSelect from "../../components/form-inputs-label-select/FormSelect";
import FormTextArea from "../../components/form-inputs-label-select/FormTextArea";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";
import MainBtn from "../../components/buttons/MainBtn";
import { useSendEmail } from "../../utils/email.api";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const sendEmail = useSendEmail();

  const [from, setFrom] = useState("");
  const [sort, setSort] = useState("");
  const [subject, setSubject] = useState("");
  const [contents, setContents] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = { from, sort, subject, contents };
    if (!from || !sort || !subject || !contents) {
      toast({
        title: "Error",
        description: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const response: any = await sendEmail
        .mutateAsync(formData)
        .then((res) => {
          setFrom("");
          setSort("");
          setSubject("");
          setContents("");
          toast({
            title: "Email Sent",
            description: "Your email has been sent successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          navigate("/");
        })
        .catch((err) => {
          toast({
            title: "Error",
            description:
              err?.message || "Something went wrong. Please try again later.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while sending the email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      console.log("Error:", error);
    }
  };

  const handleSortChange = (selectedValue: string) => {
    setSort(selectedValue);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Flex
        mb={isWeb ? "64px" : "40px"}
        direction={"column"}
        px={isWeb ? undefined : "16px"}
        gap={"24px"}
        maxWidth={"1240px"}
        width={"100%"}
      >
        <Flex
          maxWidth={"1240px"}
          width={"100%"}
          p={isWeb ? "16px" : "0px"}
          direction={"column"}
          alignItems={isWeb ? "flex-start" : "center"}
          gap={isWeb ? "16px" : "14px"}
          borderRadius={isWeb ? "12px" : undefined}
          bg={isWeb ? "#1E1E1E" : undefined}
        >
          <Box
            w={isWeb ? "488px" : "100%"}
            maxWidth={isWeb ? "488px" : "385px"}
          >
            <FormLabel label={"From"} />
            <FormInput
              h={"48px"}
              type={"email"}
              name="from"
              placeholder={"Write your e-mail"}
              fontStyle={"normal"}
              value={from}
              onChange={(e: any) => setFrom(e.target.value)}
              required
            />
          </Box>
          <Box
            w={isWeb ? "488px" : "100%"}
            maxWidth={isWeb ? "488px" : "385px"}
          >
            <FormLabel label={"Sort"} />
            <FormSelect
              h={"48px"}
              name="sort"
              value={sort}
              onChange={handleSortChange}
            ></FormSelect>
          </Box>

          <Box
            w={isWeb ? "1208px" : "100%"}
            maxWidth={isWeb ? "1208px" : "385px"}
          >
            <FormLabel label={"Subject"} />
            <FormInput
              h={"48px"}
              type={"text"}
              name="subject"
              fontStyle={"normal"}
              placeholder="Enter subject"
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
              required
            />
          </Box>

          <Box
            w={isWeb ? "1208px" : "100%"}
            maxWidth={isWeb ? "1208px" : "385px"}
          >
            <FormLabel label={"Contents"} />
            <FormTextArea
              name="contents"
              fontStyle={"normal"}
              placeholder="Enter contents"
              value={contents}
              setContents={setContents}
              required
            />
          </Box>
        </Flex>

        <Box alignSelf={isWeb ? "flex-end" : "center"}>
          <MainBtn
            width={isWeb ? "292px" : "100%"}
            height={isWeb ? "40px" : "50px"}
            padding={isWeb ? "8px" : "13px"}
            text={"CONFIRM AND SEND"}
            bg
            letterSpacing={"-0.5px"}
            onClick={handleSubmit} // Trigger form submission
          />
        </Box>
      </Flex>
    </form>
  );
};

export default ContactForm;

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  colors: {
    primary: "#DD1B78",
    secondary: "#FBFF42",
    heading: "#FFF",
    bg: "#000",
  },
  // config: {
  //   initialColorMode: "light",
  //   useSystemColorMode: false,
  // },
});

export default theme;

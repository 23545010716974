import { Select } from "@chakra-ui/react";
import React from "react";

const FormSelect = ({ h, mt, w, br, placeholder, onChange }: any) => {
  return (
    <Select
      minH={"48px"}
      width={w ? w : "100%"}
      height={h ? h : "202px"}
      mt={mt ? mt : "4px"}
      // placeholder={placeholder ? placeholder : "Advertisement Requires"}
      //focusBorderColor={""}
      border="1px solid #FFF"
      bg={"rgba(0, 0, 0, 0.60)"}
      borderRadius={br ? br : "8px"}
      fontFamily="Noto Sans"
      color="#FFF"
      _placeholder={{
        fontSize: "12px",
        fontWeight: 700,
      }}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="" disabled selected style={{ background: "#000" }}>
        Select
      </option>
      <option value="Help" style={{ background: "#000" }}>
        Help
      </option>
      <option value="Advertisement Inquiries" style={{ background: "#000" }}>
        Advertisement Inquiries
      </option>
      <option value="Partnership" style={{ background: "#000" }}>
        Partnership
      </option>
    </Select>
  );
};

export default FormSelect;

import { Flex, Image } from "@chakra-ui/react";
import CustomText from "../../components/text/customText";
import yolo from "../../assets/icons/table/image 1.png";
import { IconThumbUp, IconTilde } from "@tabler/icons-react";
import eth from "../../assets/icons/tag-icons/eth.svg";
import binance from "../../assets/icons/tag-icons/binance.svg";
import bit from "../../assets/icons/tag-icons/bit.svg";

export const columns = [
  {
    name: "TYPE",
    value: "TYPE",
    isChevron: false,
  },
  {
    name: "PROJECT",
    value: "PROJECT",
    isChevron: false,
  },
  {
    name: "REWARDS",
    value: "REWARDS",
    isChevron: false,
  },
  {
    name: "START DATE",
    value: "START_DATE",
    isChevron: false,
  },
  {
    name: "END DATE",
    value: "END_DATE",
    isChevron: false,
  },

  {
    name: "RATE",
    value: "RATE",
    isChevron: false,
  },
  {
    name: "STATUS",
    value: "STATUS",
    isChevron: false,
  },
  {
    name: "Like",
    value: "Like",
    isChevron: false,
  },
];

export const rows = [
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={eth} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
          whiteSpace={"nowrap"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#DD1B78"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"ONGOING"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={bit} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#0ABBB0"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"CLOSE SOON"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={binance} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#DD1B78"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"ONGOING"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={eth} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#0ABBB0"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"CLOSE SOON"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={bit} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#FBFF42"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"OPEN SOON"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
  {
    TYPE: (
      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"25px"}
        width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={binance} boxSize={"18px"} />
      </Flex>
    ),
    PROJECT: (
      <Flex gap={"12px"} alignItems={"center"}>
        <Image src={yolo} alt="ico" sizes="40px" borderRadius={"160px"} />
        <CustomText
          text={"YOLO Games"}
          color={"#FFF"}
          size={"20px"}
          weight={"500"}
          fontFamily={"Montserrat"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    REWARDS: (
      <CustomText
        text={"20K NFT"}
        color={"#FFF"}
        size={"16px"}
        weight={400}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    ),
    DATE: (
      <Flex gap={"12px"} alignItems={"center"}>
        <CustomText
          text={"24.04.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
        <IconTilde size={"10px"} color="#FFF" />
        <CustomText
          text={"24.05.00"}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),

    RATE: (
      <Flex gap={"8px"} alignItems={"center"}>
        <IconThumbUp size={"16px"} color="#FFF" />

        <CustomText
          text={"999"}
          color={"#FFF"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    STATUS: (
      <Flex
        bg={"#FFF"}
        borderRadius={"100px"}
        maxWidth={"118px"}
        width={"100%"}
        p={"2px 4px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CustomText
          text={"CLOSED"}
          color={"#000"}
          size={"16px"}
          weight={700}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      </Flex>
    ),
    Like: true,
  },
];
export const emptyRows: any = [];

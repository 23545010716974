import { Textarea } from "@chakra-ui/react";
import React from "react";

const FormTextArea = ({
  h,
  mt,
  w,
  br,
  placeholder,
  fontstyle,
  setContents,
}: any) => {
  return (
    <Textarea
      p={"8px 16px"}
      minH={"48px"}
      width={w ? w : "100%"}
      height={h ? h : "202px"}
      mt={mt ? mt : "4px"}
      placeholder={placeholder}
      //focusBorderColor={""}
      border="1px solid #FFF"
      bg={"rgba(0, 0, 0, 0.60)"}
      borderRadius={br ? br : "8px"}
      fontFamily="Noto Sans"
      textColor={"#FFF"}
      _placeholder={{
        fontStyle: fontstyle ? fontstyle : "italic",
        fontSize: "12px",
        fontWeight: 700,
      }}
      onChange={(e: any) => setContents(e.target.value)}
    />
  );
};

export default FormTextArea;

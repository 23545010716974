import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";

/// USER LOGIN \\\
export const useLogin = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/login", {
        method: "POST",
        data: loginData,
      });

      console.log("response we're getting", response);
      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.token) {
        localStorage.setItem("token", response.token.access_token);
        localStorage.setItem("id", response.user.id);
        //queryClient.invalidateQueries({ queryKey: ["token"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useLogVisit = () => {
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client("auth/logVisit", {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const usePageVisit = () => {
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client("auth/pageVisit", {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

/// USER SIGNUP  \\\
export const useSignUp = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (data) => {
      const response = await client("auth/signup", {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

/// GET INFO ABOUT LOGGED-IN USER \\\
export const useGetLoggedinUser = () =>
  useQuery({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => {
      const response = await client(`auth/getLoggedInUser`);
      return response;
    },
  });

//// remove/unregiter the current user \\\
export const useRemoveAccount = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (data) => {
      const response = await client("auth/deleteProfile", {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGoogleLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/loginWithGoogle", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.token) {
        localStorage.setItem("token", response.token.access_token);
        localStorage.setItem(
          "googleProfileEmail",
          response.user.googleProfileEmail
        );
        localStorage.setItem(
          "googleProfileImage",
          response.user.googleProfileImage
        );
        localStorage.setItem("nickname", response.user.nickname);
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useConnectWithGoogle = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/connectWithGoogle", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useFacebookLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/loginWithFacebook", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        // localStorage.setItem("nickname", response.user.nickname);
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useConnectWithFacebook = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/connectWithFacebook", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        // localStorage.setItem("token", response.token.access_token);
        // localStorage.setItem(
        //   "facebookProfileEmail",
        //   response.user.facebookProfileEmail
        // );
        // localStorage.setItem(
        //   "facebookProfileImage",
        //   response.user.facebookProfileImage
        // );
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useAppleLogin = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/loginWithApple", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response?.token) {
        localStorage.setItem("token", response.token.access_token);
        localStorage.setItem(
          "appleProfileEmail",
          response.user.googleProfileEmail
        );
        localStorage.setItem(
          "appleProfileImage",
          response.user.googleProfileImage
        );
        localStorage.setItem("nickname", response.user.nickname);
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useConnectWithApple = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/connectWithApple", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        // localStorage.setItem("token", response.token.access_token);
        // localStorage.setItem(
        //   "appleProfileEmail",
        //   response.user.appleProfileEmail
        // );
        // localStorage.setItem(
        //   "appleProfileImage",
        //   response.user.appleProfileImage
        // );
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useDeleteProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/deleteProfile", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        localStorage.clear();
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client("auth/updateAccount", {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }

      if (response) {
        localStorage.clear();
        queryClient.invalidateQueries({ queryKey: ["getToken"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};

import { Flex } from "@chakra-ui/react";
import { IconArrowNarrowLeft } from "@tabler/icons-react";
import React from "react";
import CustomText from "../text/customText";

const BackBtn = ({ size, arrow, onClick }: any) => {
  return (
    <Flex
      cursor={"pointer"}
      alignItems={"center"}
      gap={"8px"}
      alignSelf={"start"}
      onClick={onClick}
    >
      <IconArrowNarrowLeft color="#FFF" size={size ? size : "24px"} />

      {!arrow && (
        <CustomText
          fontFamily={"Montserrat"}
          text={"Back"}
          fontSize={"16px"}
          weight={700}
          lineHeight={"16px"}
          letterSpacing={"-0.5px"}
          color={"#FFF"}
        />
      )}
    </Flex>
  );
};

export default BackBtn;

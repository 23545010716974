import { Flex, Image, Spinner } from "@chakra-ui/react";
import React from "react";
import theme from "../../theme/theme";
import CustomText from "../text/customText";

const MainBtn = ({
  width,
  height,
  padding,
  text,
  border,
  bg,
  onClick,
  br,
  letterSpacing,
  fontSize,
  fontWeight,
  color,
  bgcolor,
  textColor,
  icon,
  iconSize,
  isLoading,
  disabled,
  disColor,
}: any) => {
  return (
    <Flex
      border={border ? `1px solid` : 0}
      borderColor={
        color ? color : disColor && disabled ? disColor : theme.colors.primary
      }
      bg={
        bg
          ? bgcolor
            ? bgcolor
            : disColor && disabled
            ? disColor
            : `${theme.colors.primary}`
          : "transparent"
      }
      width={width ? width : "100%"}
      maxWidth={width ? width : "400px"}
      height={height ? height : "50px"}
      p={padding ? padding : "13px 57px"}
      justifyContent={"center"}
      alignItems={"center"}
      cursor={disabled ? "not-allowed" : "pointer"}
      onClick={onClick}
      pointerEvents={disabled ? "none" : "auto"}
      borderRadius={br ? br : "12px"}
      gap={"7px"}
    >
      {icon && (
        <Image
          alt="icon"
          src={icon}
          width={iconSize ? iconSize : "24px"}
          height={iconSize ? iconSize : "24px"}
        />
      )}
      {isLoading ? (
        <Spinner size="sm" color="#fff" />
      ) : (
        <CustomText
          fontFamily={"Montserrat"}
          text={text}
          color={
            disColor && border && disabled
              ? disColor
              : textColor
              ? textColor
              : bg
              ? theme.colors.heading
              : color
              ? color
              : theme.colors.primary
          }
          // color={
          //   bg
          //     ? `${theme.colors.heading}`
          //     : color
          //     ? color
          //     : `${theme.colors.primary}`
          // }
          size={fontSize ? fontSize : "24px"}
          weight={fontWeight ? fontWeight : 700}
          letterSpacing={letterSpacing}
        />
      )}
    </Flex>
  );
};

export default MainBtn;

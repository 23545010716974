import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import yolo from "../../../../assets/icons/table/yolo.png";
import CustomText from "../../../text/customText";
import { IconPlus, IconThumbUp } from "@tabler/icons-react";
import twitter from "../../../../assets/icons/tag-icons/twitter.svg";
import mail from "../../../../assets/icons/tag-icons/mail.svg";
import diamond from "../../../../assets/icons/tag-icons/diamond.svg";
import notes from "../../../../assets/icons/tag-icons/notes.svg";
import { format } from "date-fns";

const ClaimHeaderMobile = ({ data, soon, closed, onClick }: any) => {
  return (
    <Flex
      direction={"column"}
      justifyContent={"center"}
      gap={"8px"}
      alignItems={"center"}
    >
      <Image
        alt={data ? data.profilePictureUrl : "dummy"}
        src={data ? data.profilePictureUrl : yolo}
        width={"60px"}
        height={"60px"}
      />

      <Flex
        borderRadius={"100px"}
        bg={"#3E414A"}
        height={"18px"}
        // width={"25px"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"4px"}
        p={"3px 4px"}
      >
        <IconThumbUp color="#FFF" size={"14px"} />

        <CustomText
          text={data ? data.positiveRatingCount : "+1KDummy"}
          color={"#FFF"}
          fontFamily={"Montserrat"}
          align={"center"}
          size={"11px"}
          weight={700}
          h={"11px"}
          letterSpacing={"-0.5"}
        />
      </Flex>

      <CustomText
        text={data ? data.name : "dummy name"}
        fontFamily={"Montserrat"}
        size={"24px "}
        weight={500}
        h={"24px"}
        letterSpacing={"-0.5px"}
        color={"#FFF"}
      />

      <CustomText
        text={`${format(
          new Date(data ? data?.airdropStartTime : "2024-05-10T10:44:57.567Z"),
          "MMM/dd/yyyy"
        )} ~${
          data?.airdropEndTime
            ? format(new Date(data?.airdropEndTime), "MMM/dd/yyyy")
            : "N/A"
        }`}
        color={"rgba(255, 255, 255, 0.60)"}
        fontFamily={"Montserrat"}
        align={"center"}
        size={"12px"}
        weight={400}
        h={"12px"}
        letterSpacing={"-0.5"}
      />

      {/* tags */}
      <Flex gap={"8px"} alignItems={"center"}>
        <Flex
          borderRadius={"100px"}
          bg={"#3E414A"}
          height={"18px"}
          width={"18px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image src={data?.projectType?.icon} width={"14px"} height={"14px"} />
        </Flex>
        <Flex
          borderRadius={"100px"}
          bg={"#3E414A"}
          height={"18px"}
          // width={"25px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"4px"}
          p={"3px 4px"}
        >
          <Image src={diamond} boxSize={"14px"} />
          <CustomText
            text={data ? data.rewards : "20K NFT dummy"}
            color={"#FFF"}
            fontFamily={"Montserrat"}
            align={"center"}
            size={"12px"}
            weight={400}
            h={"12px"}
            letterSpacing={"-0.5"}
          />
        </Flex>

        <Flex
          borderRadius={"100px"}
          bg={"#3E414A"}
          height={"18px"}
          // width={"25px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"8px"}
          p={"3px 4px"}
        >
          {data?.projectRequirements?.map((link: any, index: number) => (
            <Image key={index} alt="icon" src={link.icon} boxSize={"14px"} />
          ))}

          {/* <Image src={twitter} boxSize={"14px"} />
          <Image src={notes} boxSize={"14px"} />

          <Image src={mail} boxSize={"14px"} /> */}
          <IconPlus size={"14px"} color="#FFF" />

          <CustomText
            text={"REQUIRED"}
            color={"#FFF"}
            fontFamily={"Montserrat"}
            align={"center"}
            size={"12px"}
            weight={400}
            h={"12px"}
            letterSpacing={"-0.5"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClaimHeaderMobile;

import { Box, Flex, Image, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import yolo from "../../../../assets/icons/other/yolo.svg";
import MainBtn from "../../../buttons/MainBtn";
import star from "../../../../assets/icons/other/unliked.svg";
import filledStar from "../../../../assets/icons/other/liked.svg";
import CustomText from "../../../text/customText";
import { IconPlus, IconTilde } from "@tabler/icons-react";
import solanaIco from "../../../../assets/icons/other/solana.png";
import Toggle from "../Toggle";

import diamond from "../../../../assets/icons/tag-icons/diamond.svg";
import { format } from "date-fns";
import {
  useAddFavouriteProject,
  useClaimById,
  useRemoveFavouriteProject,
} from "../../../../utils/airdrop.api";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const ClaimHeader = ({ projectId, soon, closed, data }: any) => {
  const { data: token } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  const navigate = useNavigate();

  const [isToggle, setIsToggle] = useState(false);
  const [claimText, setClaimText] = useState("CLAIM");
  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const [isFavorite, setIsFavorite] = useState(data?.isFavorite);

  // Custom hook to add project to favorites
  const addFavouriteProjectMutation = useAddFavouriteProject(projectId);
  // Custom hook to remove project from favorites
  const removeFavouriteProjectMutation = useRemoveFavouriteProject(projectId);
  // Custom hook to claim project
  const claimAirdropMutation = useClaimById(projectId);

  const handleStarClick = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (isFavorite) {
      // Trigger remove project from favorites mutation
      await removeFavouriteProjectMutation.mutateAsync(projectId);
      setIsFavorite(false);
    } else {
      // Trigger add project to favorites mutation
      await addFavouriteProjectMutation.mutateAsync(projectId);
      setIsFavorite(true);
    }
  };

  const handleClaimClick = async () => {
    try {
      setClaimText("CLAIMED");
      await claimAirdropMutation.mutateAsync(projectId);
      window?.open(data?.airdropWebsite, "_blank");
      console.log("Claim successful");
    } catch (error) {
      console.error("Claim failed", error);
    }
  };

  return (
    <Flex gap={"78px"} alignItems={"center"} width={"100%"}>
      <Image
        src={data ? data.profilePictureUrl : yolo}
        alt="profile"
        width={"158px"}
        height={"113px"}
      />
      <Flex direction={"column"} gap={"24px"} width={"100%"}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Flex gap={"12px"} alignItems={"center"}>
            {addFavouriteProjectMutation.isPending ? (
              <Spinner color="white" />
            ) : data?.isFavorite ? (
              <Image
                src={filledStar}
                alt="star icon"
                height={"36px"}
                width={"36px"}
                cursor={"pointer"}
                onClick={handleStarClick}
              />
            ) : removeFavouriteProjectMutation.isPending ? (
              <Spinner color="white" />
            ) : (
              <Image
                src={star}
                alt="star icon"
                height={"36px"}
                width={"36px"}
                cursor={"pointer"}
                onClick={handleStarClick}
              />
            )}

            <CustomText
              text={data ? data.name : "YOLO Games"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              align={"center"}
              size={"40px"}
              weight={500}
              h={"40px"}
              letterSpacing={"-0.5"}
            />
          </Flex>

          <Flex direction={"column"} gap={"8px"} alignItems={"flex-end"}>
            <Flex gap={"5px"} alignItems={"center"}>
              <CustomText
                text={
                  data
                    ? format(new Date(data.airdropStartTime), "MMM/dd/yyyy")
                    : "dummyTime"
                }
                color={"#999999"}
                size={"16px"}
                weight={400}
                fontFamily={"Montserrat"}
                h={"16px"}
                letterSpacing={"-0.5px"}
              />
              <IconTilde size={"16px"} color={"#999999"} />
              <CustomText
                text={
                  data?.airdropEndTime
                    ? format(new Date(data.airdropEndTime), "MMM/dd/yyyy")
                    : "N/A"
                }
                color={"#999999"}
                size={"16px"}
                weight={400}
                fontFamily={"Montserrat"}
                h={"16px"}
                letterSpacing={"-0.5px"}
              />
            </Flex>

            <Flex gap={"8px"} alignItems={"center"}>
              <Flex
                borderRadius={"100px"}
                bg={"#3E414A"}
                height={"25px"}
                // width={"25px"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"4px"}
                p={"4px 8px"}
              >
                <Image
                  src={data ? data.projectType.icon : solanaIco}
                  alt="icon"
                  width={"18px"}
                  height={"18px"}
                />
                <CustomText
                  text={data ? data.projectType.title : "dummy"}
                  color={"#FFF"}
                  fontFamily={"Montserrat"}
                  align={"center"}
                  size={"16px"}
                  weight={400}
                  h={"16px"}
                  letterSpacing={"-0.5"}
                />
              </Flex>
              <Flex
                borderRadius={"100px"}
                bg={"#3E414A"}
                height={"25px"}
                // width={"25px"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"4px"}
                p={"4px 8px"}
              >
                <Image src={diamond} boxSize={"18px"} />
                <CustomText
                  text={data ? data.rewards : " dummy 20K NFT"}
                  color={"#FFF"}
                  fontFamily={"Montserrat"}
                  align={"center"}
                  size={"16px"}
                  weight={400}
                  h={"16px"}
                  letterSpacing={"-0.5"}
                />
              </Flex>

              <Flex
                borderRadius={"100px"}
                bg={"#3E414A"}
                height={"25px"}
                // width={"25px"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"8px"}
                p={"4px 8px"}
              >
                {data?.projectRequirements?.map((link: any, index: number) => (
                  <Image
                    key={index}
                    alt="icon"
                    src={link.icon}
                    boxSize={"18px"}
                  />
                ))}

                {/* <Image src={twitter} boxSize={"18px"} />
                <Image src={notes} boxSize={"18px"} />

                <Image src={mail} boxSize={"18px"} /> */}
                <IconPlus size={"16px"} color="#FFF" />

                <CustomText
                  text={"REQUIRED"}
                  color={"#FFF"}
                  fontFamily={"Montserrat"}
                  align={"center"}
                  size={"16px"}
                  weight={400}
                  h={"16px"}
                  letterSpacing={"-0.5"}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {!soon && !closed && (
          <Flex gap={"20px"} alignItems={"center"} width={"100%"}>
            <Box width={"100%"}>
              {" "}
              <MainBtn
                height="48px"
                width={"100%"}
                bg
                text={claimText}
                /*   DONT REMOVE BELOW CODE IT WILL BE USE IN FUTURE

              this below code is commented 
              when client give us some endpoint if  user cliamed or not then this code will work perfectly
              so DONT REMOVE BELOW CODE IT WILL BE USE IN FUTURE 
              */
                // text={data?.isClaimed ? "CLAIMED" : claimText}
                disabled={
                  data?.isClaimed || claimText === "CLAIMED" ? true : false
                }
                onClick={handleClaimClick}
                disColor={"#3e414a"}
              />
            </Box>

            <Box
              width={"100%"}
              minWidth={"504px"}
              position={"relative"}
              onClick={data?.isRated ? undefined : handleToggle}
              //    cursor={data?.isRated ? "default" : "pointer"}
            >
              <MainBtn
                height="48px"
                width={"100%"}
                border
                text={data?.isRated ? "RATED" : "RATE"}
                disabled={data?.isRated ? true : false}
                disColor={"#3e414a"}
              />
              {isToggle && (
                <Box
                  position={"absolute"}
                  top={"56px"}
                  right={0}
                  width={"100%"}
                  zIndex={999}
                >
                  <Toggle rate projectId={projectId} />
                </Box>
              )}
            </Box>
          </Flex>
        )}

        {soon && (
          <Flex width={"100%"}>
            <MainBtn
              height="48px"
              width={"100%"}
              border
              text={"OPEN SOON "}
              color={"#FBFF42"}
              onClick={() => {
                console.log("no action");
              }}
              disabled
            />
          </Flex>
        )}

        {closed && (
          <Flex width={"100%"}>
            <MainBtn
              height="48px"
              width={"100%"}
              text={"CLAIM CLOSED "}
              bgcolor={"#1E1E1E"}
              bg
              textColor={"rgba(255, 255, 255, 0.4)"}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ClaimHeader;

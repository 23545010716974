// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzNMb2AZzJuctE9ShwhWUWAEvJFlc7IpY",
  authDomain: "blockvestor-3111c.firebaseapp.com",
  projectId: "blockvestor-3111c",
  storageBucket: "blockvestor-3111c.appspot.com",
  messagingSenderId: "716198057467",
  appId: "1:716198057467:web:a59cfee9513b2eb269675e",
  measurementId: "G-M0RHTQHK72",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { Flex, Spinner, useMediaQuery, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import AuthLayout from "../../../layout/AuthLayout";
import AuthHeading from "../../../components/auth/AuthHeading";
import AuthOption from "../../../components/auth/AuthOption";
import fbIcon from "../../../assets/icons/social/facebook.svg";
import appleIcon from "../../../assets/icons/social/apple.svg";
import gooogleIcon from "../../../assets/icons/social/google.svg";
import mailIcon from "../../../assets/icons/social/mail.svg";

import { useNavigate } from "react-router-dom";
import {
  useAppleLogin,
  useFacebookLogin,
  useGoogleLogin,
} from "../../../utils/auth.api";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

interface LoginForm {
  token: string;
}

const Loginby = () => {
  const [loading, setLoading] = useState({
    google: false,
    facebook: false,
    apple: false,
  });

  const navigate = useNavigate();
  const toast = useToast();
  const loginWithGoogleMutation = useGoogleLogin();
  const loginWithFacebookMutation = useFacebookLogin();
  const loginWithAppleMutation = useAppleLogin();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = () => {
    setLoading({
      ...loading,
      google: true,
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user: any = result.user;

        // console.log(user);

        const formData = {
          token: user.accessToken,
        };
        setLoading({
          ...loading,
          google: true,
        });
        await loginWithGoogleMutation?.mutateAsync(formData);
        toast({
          title: "Email Verified",
          description: "You have successfully verified your email",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        sessionStorage.clear();
      })
      .catch((error) => {
        setLoading({
          ...loading,
          google: false,
        });
        toast({
          title: error?.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading({
          ...loading,
          google: false,
        });
      });
  };

  const handleFacebookLogin = () => {
    const provider = new FacebookAuthProvider();
    setLoading({
      ...loading,
      facebook: true,
    });

    console.log("result");
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user: any = result.user;

        console.log(user);
        const formData: LoginForm = {
          token: await user.accessToken,
        };

        console.log(formData);

        await loginWithFacebookMutation.mutateAsync(formData);

        toast({
          title: "Email Verified",
          description: "You have successfully verified your email",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        sessionStorage.clear();

        navigate("/"); // Adjust the navigation target as needed
      })
      .catch((error) => {
        toast({
          title: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading({
          ...loading,
          facebook: false,
        });
      })
      .finally(() => {
        setLoading({
          ...loading,
          facebook: false,
        });
      });
  };

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider("apple.com");
    setLoading({
      ...loading,
      apple: true,
    });

    try {
      const result: UserCredential = await signInWithPopup(auth, provider);

      // Extract user information
      const user = result.user;
      const idToken = await user.getIdToken();

      const formData: LoginForm = {
        token: idToken,
      };

      // Call your backend API to handle the login
      await loginWithAppleMutation.mutateAsync(formData);

      // Show success toast and navigate to home page
      toast({
        title: "Email Verified",
        description: "You have successfully verified your email",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      sessionStorage.clear();
      navigate("/"); // Redirect to home page after successful login
    } catch (error: any) {
      // Handle errors
      toast({
        title: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      setLoading({
        ...loading,
        apple: false,
      });
    } finally {
      setLoading({
        ...loading,
        apple: false,
      });
    }
  };

  const [isLessThan350] = useMediaQuery("(max-width: 350px)");
  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
      >
        <AuthHeading title="LOG IN" />
        <Flex
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"16px"}
          width={"100%"}
        >
          {loading?.google ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={`Continue with Google`}
              icon={gooogleIcon}
              /// isGoogle
              onClick={handleGoogleSignIn}
            />
          )}
          {loading?.facebook ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={"Continue with Facebook"}
              icon={fbIcon}
              onClick={handleFacebookLogin}
            />
          )}

          {loading?.apple ? (
            <Flex justify="center" my="20px">
              <Spinner size="md" color="#fff" />
            </Flex>
          ) : (
            <AuthOption
              text={"Continue with Apple"}
              icon={appleIcon}
              onClick={handleAppleLogin}
            />
          )}
          <AuthOption
            text={"Continue with email"}
            icon={mailIcon}
            onClick={() => navigate("/login/email")}
          />
        </Flex>

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: isLessThan350 ? "15px" : "16px",

            fontWeight: 400,
            lineHeight: "16px",
            alignSelf: "center",
          }}
        >
          Don’t you have an account?
          <span
            style={{
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: isLessThan350 ? "15px" : "16px",

              fontWeight: 700,
              lineHeight: "100%",
              textDecoration: "underline",
              cursor: "pointer",
              alignSelf: "center",
            }}
            onClick={() => navigate("/signup")}
          >
            {" "}
            Sign up
          </span>{" "}
        </p>
      </Flex>
    </AuthLayout>
  );
};

export default Loginby;

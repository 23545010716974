import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/customText";

const Tag = ({ p, icon, text, bg, border, onClick }: any) => {
  return (
    <Flex
      p={p ? p : "8px"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={bg ? bg : "#DD1B78"}
      borderRadius={"100px"}
      gap={"10px"}
      border={border}
      onClick={onClick}
      cursor={"pointer"}
    >
      {icon && <Image alt="icon" src={icon} boxSize={"16px"} />}

      <CustomText
        text={text ? text : "PROFILE"}
        color={"#FFF"}
        size={"16px"}
        weight={500}
        fontFamily={"Montserrat"}
        h={"16px"}
        letterSpacing={"-0.5px"}
      />
    </Flex>
  );
};

export default Tag;

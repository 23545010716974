import { Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CardTabs from "../CardTabs";
import Detail from "./Detail";
import Overview from "./Overview";
import CommentCardContent from "../comment/CommentCardContent";
import Guide from "./Guide";
import { breakpoint } from "../../../../breakpoint-config/Breakpoint";
const DetailCard = ({
  data,
  commentData,
  onClick,
  isLogin,
  handleLogin,
  isCommentSection,
  setSortOrder,
  sortOrder,
  user,
  isLoading,
}: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);

  const [overView, setOVerView] = useState(false);
  const [comment, setComment] = useState(false);
  const [guide, setGuide] = useState(false);
  const [activeDetail, setActiveDetail] = useState(true);
  const handleDetail = () => {
    setActiveDetail(true);
    setOVerView(false);
    setComment(false);
    isCommentSection(false);
    setGuide(false);
  };

  const handleOverView = () => {
    setOVerView(true);
    setActiveDetail(false);
    setComment(false);
    setGuide(false);
    isCommentSection(false);
  };

  const handleComment = () => {
    setComment(true);
    isCommentSection(true);
    setOVerView(false);
    setActiveDetail(false);
    setGuide(false);
  };

  const handleGuide = () => {
    setGuide(true);
    setComment(false);
    setOVerView(false);
    setActiveDetail(false);
    isCommentSection(false);
  };

  useEffect(() => {}, [data]);

  return (
    <Flex
      //maxWidth={isWeb ? "610px" : "100%"}
      maxWidth={"610px"}
      p={isWeb ? "32px 32px" : "16px "}
      direction={"column"}
      gap={"24px"}
      borderRadius={isWeb ? "0px 8px 0px 0px" : " 8px 8px 0px 0px;"}
      bg={"#3E414A"}
      width={"100%"}
      position={"relative"}
    >
      {/* here are the tabs */}
      <Flex
        gap={isWeb ? "12px" : isLessThan370 ? "4px" : "8px"}
        alignItems={"center"}
        position={"absolute"}
        top={"-40px"}
        left={isWeb ? 0 : undefined}
        alignSelf={isWeb ? "flex-start" : "center"}
      >
        <CardTabs
          text={"DETAIL"}
          isActive={activeDetail}
          onClick={handleDetail}
        />
        {/* <CardTabs text={"GUIDE"} isActive={guide} onClick={handleGuide} />
        <CardTabs
          text={"OVERVIEW"}
          isActive={overView}
          onClick={handleOverView}
        /> */}

        {!isWeb && (
          <CardTabs
            text={"COMMENT"}
            isActive={comment}
            onClick={handleComment}
          />
        )}
      </Flex>

      {activeDetail && <Detail data={data} onClick={onClick} />}

      {/* {overView && <Overview data={data} />}

      {guide && <Guide data={data} />} */}

      {!isWeb && comment && (
        <CommentCardContent
          isLogin={isLogin}
          onClick={handleLogin}
          data={commentData}
          user={user}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          isLoading={isLoading}
        />
      )}
    </Flex>
  );
};

export default DetailCard;

import { Box, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/dashboard/navbar/Navbar";
import Footer from "../../components/dashboard/footer/Footer";
import MenuBar from "../../components/dashboard/bottom-menubar/MenuBar";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";

const DashboardLayout = (props: any) => {
  const [isWeb] = useMediaQuery("(min-width: 1240px)");
  return (
    <>
      {" "}
      {isWeb ? (
        <>
          <Box
            minHeight={"100vh"}
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            maxWidth={"1540px"}
            alignItems={"center"}
            // justifyContent={"center"}
            mx={"auto"}
            position={"relative"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              alignItems={"center"}
            >
              <Navbar />
              <Box maxW="1240px" width="100%">
                {props?.children}
              </Box>
            </Box>

            <Footer />
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          mx={"auto"}
          position={"relative"}
        >
          <Navbar />
          <Box maxW="1240px" width="100%">
            {props?.children}
            <Footer />
          </Box>

          <MenuBar />
        </Box>
      )}
    </>
  );
};

export default DashboardLayout;

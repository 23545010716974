import {
  Avatar,
  Box,
  Flex,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../../text/customText";

import editIcon from "../../../../../assets/icons/other/editMobile.svg";
import eraseIcon from "../../../../../assets/icons/other/eraseMobile.svg";
import {
  IconDotsVertical,
  IconPointFilled,
  IconThumbUp,
  IconX,
} from "@tabler/icons-react";
import Toggle from "../../Toggle";
import { breakpoint } from "../../../../breakpoint-config/Breakpoint";
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";
import {
  useCommentDelete,
  useCommentDislike,
  useCommentLike,
  useUpdateComment,
} from "../../../../../utils/comment.api";
import { useGetLoggedinUser } from "../../../../../utils/auth.api";
import MainBtn from "../../../../buttons/MainBtn";

const Comment = ({ key, comment }: any) => {
  const { projectId } = useParams();
  const formattedTime = (time: any) => {
    const distance = formatDistanceToNow(new Date(time), { addSuffix: true });

    // Convert distance to lower case for consistent formatting
    return distance.toLowerCase();
  };

  const [isToggleOpen, setIsToggleOpen] = useState(false); //for comment toggle web
  const toggleCommentToggle = () => {
    setIsToggleOpen(!isToggleOpen);
  };
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);

  //for comment delete in mobile

  const commentRemove = useCommentDelete(comment?._id);

  const handleDelete = async () => {
    // Call the mutation function to delete the comment
    console.log("comment ID:", comment?._id);
    if (comment?._id) {
      await commentRemove.mutateAsync(comment?._id);
      toggleCommentToggle();
    }
  };
  ////
  ///for editing the comment
  const updateCommentMutation = useUpdateComment(comment?._id);
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(comment?.text);

  const handleEdit = () => {
    toggleCommentToggle();
    setIsEdit(!isEdit);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setText(comment?.text);
  };

  const handleConfirm = async () => {
    try {
      await updateCommentMutation.mutateAsync({
        text,
        projectId,
      });
      setIsEdit(false);
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  ////

  // const [isToggle, setIsToggle] = useState(false);
  const [like, setLike] = useState(comment?.myReaction || false);
  const [count, setCount] = useState(comment?.reactionCount);
  const [likeLoading, setLikeLoading] = useState(false);

  const commentLikeMutation = useCommentLike(); //API for comment like
  const commentUnlikeMutation = useCommentDislike(); //API for comment unlike
  const { data: loggedInUser } = useGetLoggedinUser(); // Get the logged-in user data

  const handleLike = async () => {
    if (likeLoading) return; //API calldisable

    setLikeLoading(true);

    if (!like) {
      try {
        setLike(true);
        setCount(count + 1);
        // Call the commentLike mutation function with the required data
        const response = await commentLikeMutation.mutateAsync({
          commentId: comment?._id,
          projectId,
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLikeLoading(false);
      }
    } else {
      try {
        // console.log("data for comment reaction", comment?._id, projectId);
        setLike(false);
        setCount(count - 1);
        const response = await commentUnlikeMutation.mutateAsync({
          commentId: comment?._id,
          projectId,
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLikeLoading(false);
      }
    }

    setLike(!like);
  };

  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(() => {
    if (loggedInUser?.data && comment?.user) {
      setIsCurrentUser(loggedInUser?.data?.id === comment?.user?.[0]?.id);
    }
  }, [loggedInUser?.data, comment?.user]);

  useEffect(() => {
    setCount(comment?.reactionCount);
    setLike(comment?.myReaction || false);
    setText(comment?.text);
  }, [comment]);

  return (
    <Flex gap={"12px"} alignItems={"flex-start"} key={key}>
      <Avatar
        width={"36px"}
        height={"36px"}
        name={comment?.user?.[0]?.nickname}
        src="https://bit.ly/broken-link"
      />

      {/* <Image
        alt="icon"
        src={user ? userPro : JD}
        width={"36px"}
        height={"36px"}
      /> */}
      <Flex direction={"column"} gap={"7px"} width={"100%"}>
        {/* name and time & edit for user */}
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          position={"relative"}
        >
          <Flex gap={"8px"} alignItems={"center"}>
            <CustomText
              text={comment?.user?.[0]?.nickname}
              //text={user ? "I wrote this" : "John Doe"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              size={"12px"}
              weight={600}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
            <IconPointFilled color="#D9D9D9" size={"4px"} />

            <CustomText
              text={formattedTime(comment?.createdAt)}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              size={"12px"}
              weight={300}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
          </Flex>
          {isCurrentUser && (
            <Box onClick={toggleCommentToggle} cursor={"pointer"}>
              {commentRemove?.isPending ? (
                <Spinner color="white" />
              ) : (
                <IconDotsVertical size={"16px"} color="#D9D9D9" />
              )}{" "}
              {isToggleOpen && isWeb && comment?._id && (
                <Box position={"absolute"} top={"24px"} right={0}>
                  <Toggle commentModify={handleEdit} commentId={comment?._id} />
                </Box>
              )}
            </Box>
          )}
        </Flex>

        {!isEdit && (
          <CustomText
            text={
              comment?.text ? comment?.text : "Please share more about this!"
            }
            color={"#FFF"}
            fontFamily={"Montserrat"}
            size={"14px"}
            weight={400}
            h={"14px"}
            letterSpacing={"-0.5"}
          />
        )}

        {isEdit && (
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="flushed"
            placeholder="Flushed"
            textColor={"#FFF"}
          />
        )}
        {false && (
          <Box mt={"2px"} cursor={"pointer"}>
            <CustomText
              text={"View more"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              size={"14px"}
              weight={600}
              h={"16.8px"}
              letterSpacing={"-0.5"}
              textDecoration={"underline"}
            />
          </Box>
        )}

        {!isEdit && (
          <Flex alignItems={"center"} gap={"8px"}>
            <Box onClick={handleLike}>
              <IconThumbUp
                size={"16px"}
                color={like ? "#DD1B78" : "#FFF"}
                cursor={"pointer"}
              />
            </Box>

            <CustomText
              text={count}
              color={like ? "#DD1B78" : "#FFF"}
              fontFamily={"Montserrat"}
              size={"14px"}
              weight={400}
              h={"14px"}
              letterSpacing={"-0.5"}
            />
          </Flex>
        )}

        {isEdit && (
          <Flex gap={"4px"} alignItems={"center"} alignSelf={"flex-end"}>
            <Box onClick={handleCancel} cursor={"pointer"}>
              <CustomText
                text={"cancel"}
                color={"#FFF"}
                fontFamily={"Montserrat"}
                size={"14px"}
                weight={400}
                h={"14px"}
                letterSpacing={"-0.5"}
              />
            </Box>
            {/* {updateCommentMutation?.isPending ? (
              <Spinner size="sm" color="#fff" />
            ) : ( */}
            <Flex
              p={"2px 8px"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"100px"}
              bg={"#1E1E1E"}
              boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
              onClick={handleConfirm}
              cursor={"pointer"}
              pointerEvents={updateCommentMutation.isPending ? "none" : "auto"}
            >
              {updateCommentMutation?.isPending ? (
                <Spinner size="sm" color="#fff" />
              ) : (
                <CustomText
                  text={"confirm"}
                  color={"#FFF"}
                  fontFamily={"Montserrat"}
                  size={"14px"}
                  weight={600}
                  h={"14px"}
                  letterSpacing={"-0.5"}
                />
              )}
            </Flex>
            {/* )} */}
          </Flex>
        )}
      </Flex>

      {/* Delete Modify Comment modal */}
      {!isWeb && (
        <Modal isOpen={isToggleOpen} onClose={toggleCommentToggle}>
          <ModalOverlay
            bg={"rgba(0, 0, 0, 0.80)"}
            backdropFilter={" blur(2px)"}
          />
          <ModalContent
            bg={"transparent"}
            mx={"12px"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
            height={"100%"}
          >
            <Box position={"absolute"} top={"-24px"} right={"16px"}>
              <IconX size={"34px"} color="#FFF" onClick={toggleCommentToggle} />
            </Box>

            <Flex
              width={"100%"}
              alignSelf={"center"}
              // height={"100%"}
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"12px"}
              position={"absolute"}
              bottom={"100px"}
            >
              <MainBtn
                border
                text={"MODIFY"}
                icon={editIcon}
                iconSize={"20px"}
                color={"#FFF"}
                onClick={handleEdit}
              />
              <MainBtn
                onClick={handleDelete}
                bg
                text={"DELETE"}
                icon={eraseIcon}
                iconSize={"20px"}
              />
            </Flex>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default Comment;

import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/customText";
import MainBtn from "../../components/buttons/MainBtn";
import Message from "../../components/general/Message";
import ContactForm from "./ContactForm";
import DashboardLayout from "../../layout/dashboard/DashboardLayout";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";
import { useSendEmail } from "../../utils/email.api";

const Contact = () => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);

  return (
    <>
      <DashboardLayout>
        <Flex
          mt={isWeb ? "40px" : "16px"}
          mb={isWeb ? "64px" : "40px"}
          direction={"column"}
          px={isWeb ? undefined : "16px"}
          gap={"24px"}
          maxWidth={"1240px"}
          width={"100%"}
          alignSelf={"center"}
        >
          {/* contact title and desc */}
          <Flex direction={"column"} gap={"8px"} maxWidth={"620px"}>
            <CustomText
              text={"Contact"}
              color={"#FFF"}
              size={isWeb ? "32px" : "20px"}
              weight={700}
              fontFamily={"Montserrat"}
              h={isWeb ? "32px" : "20px"}
              letterSpacing={"-0.5px"}
            />

            <CustomText
              text={
                "If you have any problems, questions, or suggestions, Feel free to contact with us. Fill this form and send,  and we’ ll give you the reply shortly."
              }
              color={"#FFF"}
              size={isWeb ? "16px" : "14px"}
              weight={400}
              fontFamily={"Montserrat"}
              h={isWeb ? "22.4px" : "19.6px"}
              letterSpacing={"-0.5px"}
            />
          </Flex>

          {/* this flex will render when user LOGED-IN  else hide, it is pending*/}
          <Flex
            maxWidth={"1240px"}
            width={"100%"}
            direction={"column"}
            gap={"16px"}
          >
            {/* <Message />
            <Message bg={"#0ABBB0"} text={"Your Profile has been saved."} /> */}
          </Flex>
          {/* contact form */}
          <ContactForm />
        </Flex>
      </DashboardLayout>
    </>
  );
};

export default Contact;

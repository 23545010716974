import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/customText";
import MainBtn from "../buttons/MainBtn";
import icon from "../../assets/icons/other/not-found.svg";

// interface emptyProps {
//   btn: boolean;
//   onClickfun?: any;
// }

const Empty = ({ btn, onClick }: any) => {
  console.log("isFavorutie at empty:", btn);
  console.log("onclick", onClick);
  return (
    <Flex
      direction={"column"}
      height={"270px"}
      alignItems={"center"}
      gap={"24px"}
      //justifyContent={isFavourite ? "center" : "space-between"}
      justifyContent={"center"}
    >
      <Image src={icon} sizes="100px" alt='"icon' />

      <Flex direction={"column"} gap={"7px"} alignItems={"center"}>
        <p
          style={{
            color: "#3E414A",
            fontFamily: "Montserrat",
            fontSize: "40px",
            fontWeight: 500,
            lineHeight: "40px",
            letterSpacing: "-0.5px",
          }}
        >
          OOPS
          <span
            style={{
              color: "#3E414A",
              fontFamily: "Montserrat",
              fontSize: "32px",
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: "-0.5px",
            }}
          >
            !
          </span>
        </p>

        <CustomText
          text={"THERE’S NOTHING TO SHOW"}
          color={"#3E414A"}
          fontFamily={"Montserrat"}
          align={"center"}
          size={"18px"}
          weight={500}
          h={"18px"}
          letterSpacing={"-0.5"}
        />
      </Flex>

      {btn && <MainBtn border text={"GO BACK"} onClick={onClick} />}
    </Flex>
  );
};

export default Empty;

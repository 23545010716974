import { useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (data: any) => {
      const response = await client(`utils/sendEmail`, {
        method: "POST",
        data: data,
      });

      return response;
    },
  });
};

import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import CustomText from "../text/customText";
import { IconTilde, IconThumbUp, IconThumbDown } from "@tabler/icons-react";
import { format } from "date-fns";

const mapDataToCustomFormat = (data: any) => {
  return data?.map((item: any) => {
    const { airdropStartTime, airdropEndTime, isActive } = item;
    const currentDate = new Date();
    const startDate = new Date(airdropStartTime);
    const endDate = new Date(airdropEndTime);

    let status;
    console.log("status data we're getting", item?.status);
    if (item?.status === "upcoming") {
      status = "OPEN SOON";
    } else if (item?.status === "closed") {
      status = "CLOSED";
    } else if (item?.status === "ongoing") {
      status = "ONGOING";
    } else if (item?.status === "closing") {
      status = "CLOSING";
    }

    let statusBackgroundColor;
    switch (status) {
      case "ONGOING":
        statusBackgroundColor = "#DD1B78";
        break;
      case "CLOSING":
        statusBackgroundColor = "#0ABBB0";
        break;
      case "OPEN SOON":
        statusBackgroundColor = "#FBFF42";
        break;
      case "CLOSED":
        statusBackgroundColor = "#FFF";
        break;
      default:
        statusBackgroundColor = "#FFF";
        break;
    }

    return {
      id: item._id,
      TYPE: (
        <Flex
          borderRadius={"100px"}
          bg={"#3E414A"}
          height={"25px"}
          width={"25px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image src={item?.projectType?.icon} boxSize={"14px"} />
        </Flex>
      ),
      PROJECT: (
        <Flex gap={"12px"} alignItems={"center"}>
          <Image
            src={item.profilePictureUrl}
            alt="ico"
            // sizes="40px"
            width={"40px"}
            height={"40px"}
            borderRadius={"160px"}
          />
          <CustomText
            text={
              item.name?.length > 15
                ? item.name.substring(0, 15) + "..."
                : item.name
            }
            color={"#FFF"}
            size={"20px"}
            weight={"500"}
            fontFamily={"Montserrat"}
            h={"20px"}
            letterSpacing={"-0.5px"}
            whiteSpace={"nowrap"}
          />
        </Flex>
      ),
      REWARDS: (
        <CustomText
          text={item.rewards}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
          align="left"
        />
      ),
      START_DATE: (
        <CustomText
          text={format(new Date(item.airdropStartTime), "MMM/dd/yyyy")}
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      ),
      END_DATE: (
        <CustomText
          text={
            item?.airdropEndTime
              ? format(new Date(item.airdropEndTime), "MMM/dd/yyyy")
              : "N/A"
          }
          color={"#FFF"}
          size={"16px"}
          weight={400}
          fontFamily={"Montserrat"}
          h={"16px"}
          letterSpacing={"-0.5px"}
        />
      ),
      REQUIREMENT: (
        <Flex alignItems={"center"} gap={"12px"} flexWrap="wrap">
          {item?.projectRequirements?.map((link: any, index: number) => (
            <Image
              key={index}
              alt="icon"
              src={link.icon}
              boxSize={"16px"}
              aspectRatio="3/2"
              objectFit="contain"
            />
          ))}
        </Flex>
      ),
      RATE: (
        <Flex gap={"8px"} alignItems={"center"}>
          {Number(item?.totalRatings) < 0 ? (
            <IconThumbDown size="16px" color="#fff" />
          ) : (
            <IconThumbUp size={"16px"} color="#FFF" />
          )}
          <CustomText
            text={
              Number(item.totalRatings) < 0
                ? item?.totalRatings * -1
                : item?.totalRatings || 0
            }
            color={"#FFF"}
            size={"16px"}
            weight={700}
            fontFamily={"Montserrat"}
            h={"16px"}
            letterSpacing={"-0.5px"}
          />
        </Flex>
      ),
      STATUS: (
        <Flex
          bg={statusBackgroundColor}
          borderRadius={"100px"}
          maxWidth={"128px"}
          width={"100%"}
          p={"2px 4px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CustomText
            text={status}
            color={"#000"}
            size={"16px"}
            weight={700}
            fontFamily={"Montserrat"}
            h={"16px"}
            whiteSpace={"nowrap"}
            letterSpacing={"-0.5px"}
          />
        </Flex>
      ),
      Like: item.isFavorite,
    };
  });
};

export default mapDataToCustomFormat;

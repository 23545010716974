import { Box, Flex, Input, useMediaQuery } from "@chakra-ui/react";
import { IconSearch } from "@tabler/icons-react";

const Searchbar = ({
  setResult,
  handleIsInput,
  isInput,
  inputValue,
  setInputValue,
}: any) => {
  const [isGreaterThan768] = useMediaQuery(`(min-width: 768px)`);

  //isInput will use in mobile view to hide or show input
  //const [isInput, setIsInput] = useState(false);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    setResult(inputValue);
    handleIsInput();
  };
  return (
    <Flex
      p={
        isGreaterThan768
          ? "2px 8px 7px 15px"
          : isInput
          ? "2px 8px 7px 15px"
          : "7px"
      }
      maxWidth={"328px"}
      width={isGreaterThan768 ? "100%" : isInput ? "100%" : "32px"}
      height={"100%"}
      borderRadius={"100px"}
      bg={"#1E1E1E"}
      maxHeight={"32px"}
      alignItems={"center"}
      gap={"9px"}
    >
      <Box
        borderBottom="1px solid #FFF"
        width={"100%"}
        maxWidth={"280px"}
        //maxH={"17px"}
        display={isGreaterThan768 ? "block" : isInput ? "block" : "none"}
      >
        <Input
          variant="unstyled"
          placeholder=""
          color="white" // Set text color to white
          _placeholder={{ color: "#FFF" }} // Set placeholder color
          onChange={handleChange}
          // onKeyDown={(event) => {
          //   if (event.key === "Enter") {
          //     handleSearch();
          //   }
          // }}
        />
      </Box>
      <IconSearch color="#FFF" size={"18px"} onClick={handleSearch} />
    </Flex>
  );
};

export default Searchbar;

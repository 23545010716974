import { Flex, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../../text/customText";
import MainBtn from "../../../../buttons/MainBtn";
import { breakpoint } from "../../../../breakpoint-config/Breakpoint";

const BeforeLogin = ({ onClick }: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  return (
    <Flex
      width={"100%"}
      p={isWeb ? "18px 16px 18px 60px" : "12px 16px "}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"8px"}
      bg={" #1E1E1E"}
      boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.25)"}
    >
      <CustomText
        fontFamily={"Montserrat"}
        text={"Log in first"}
        fontSize={"16px"}
        weight={700}
        lineHeight={"16px"}
        letterSpacing={"-0.5px"}
        color={"rgba(255, 255, 255, 0.60)"}
      />

      <MainBtn
        padding={"4px 16px"}
        fontSize={"20px"}
        fontWeight={500}
        text={"LOG IN"}
        bg
        width={"103px"}
        height={"28px"}
        br={"100px"}
        letterSpacing={"-0.5px"}
        onClick={onClick}
      />
    </Flex>
  );
};

export default BeforeLogin;

import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ClaimHeader from "../../../components/drops/detail/headers/ClaimHeader";
import DetailCard from "../../../components/drops/detail/cards/detail/DetailCard";
import CommentCard from "../../../components/drops/detail/cards/comment/CommentCard";
import cover from "../../../assets/images/game2.png";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ClaimHeaderMobile from "../../../components/drops/detail/headers/ClaimHeaderMobile";
import MainBtn from "../../../components/buttons/MainBtn";
import BackBtn from "../../../components/buttons/BackBtn";
import { useNavigate, useParams } from "react-router-dom";
import unlikeIcon from "../../../assets/icons/other/unliked.svg";
import LikedIcon from "../../../assets/icons/other/liked.svg";
import { IconX } from "@tabler/icons-react";
import CustomText from "../../../components/text/customText";
import thumbUp from "../../../assets/icons/other/thumbup.svg";
import thumbDown from "../../../assets/icons/other/thumbdown.svg";
import BeforeLogin from "../../../components/drops/detail/cards/comment/BeforeLogin";
import AfterLogin from "../../../components/drops/detail/cards/comment/AfterLogin";
import { breakpoint } from "../../../components/breakpoint-config/Breakpoint";
import {
  useAddFavouriteProject,
  useClaimById,
  // useClaimById,
  useGetAirdropById,
  useRateById,
  useRemoveFavouriteProject,
} from "../../../utils/airdrop.api";
import { useGetComments } from "../../../utils/comment.api";
import { useGetLoggedinUser } from "../../../utils/auth.api";
import { useQuery } from "@tanstack/react-query";

const DropDetail = () => {
  const { data: token } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);
  const navigate = useNavigate();

  const { projectId } = useParams();

  const [, setSoon] = useState(false);
  const [, setClaim] = useState(false);
  // const [claimed, setClaimed] = useState(false);
  const [sortOrder, setSortOrder] = useState("latest");
  const [rateToggle, setRateToggle] = useState(false);
  const [isCommentSection, setIsCommentSection] = useState(false);

  //for login handle
  const [isLogin, setIsLogin] = useState(false);

  // Fetch logged-in user data
  const { data: loggedInUserData, isLoading, isError } = useGetLoggedinUser();
  const handleLogin = () => {
    ///if user is not logged in it will nevigate to login page
    // setIsLogin(!isLogin);
    navigate("/login");
  };

  const { data: projectData } = useGetAirdropById(projectId ?? ""); // Fetch detailed data for the project
  const { data: commentsData, isPending: commentsPending } = useGetComments(
    projectId ?? "",
    sortOrder
  ); // Fetch comments data of project
  //console.log("comments data receive", commentData);
  // Custom hook to add project to favorites
  const addFavouriteProjectMutation = useAddFavouriteProject(projectId ?? "");
  // Custom hook to remove project from favorites
  const removeFavouriteProjectMutation = useRemoveFavouriteProject(
    projectId ?? ""
  );
  // Custom hook to claim project
  const claimAirdropMutation = useClaimById(projectId ?? " ");
  const rateProject = useRateById(projectId ?? " ");

  const [data, setData] = useState(projectData);
  const [commentData, setCommentData] = useState(commentsData);
  ///add/ remove favourite for mobile
  const [isFavorite, setIsFavorite] = useState(data?.isFavorite);

  const handleSoon = () => {
    setSoon(false);
    setClaim(true);
  };

  const handleRateToggle = () => {
    setRateToggle(!rateToggle);
  };
  const [isOpen, setIsOpen] = useState(false);

  const [claimText, setClaimText] = useState("CLAIM");

  const handleToggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleClaimClick = async (link: any) => {
    try {
      setClaimText("CLAIMED");
      await claimAirdropMutation.mutateAsync(projectId);
      console.log("Claim successful");
      window?.open(link, "_blank");
    } catch (error) {
      console.error("Claim failed", error);
    }
  };

  const handleStarClick = () => {
    if (!token) {
      navigate("/login");
      return;
    }
    if (isFavorite) {
      // Trigger remove project from favorites mutation
      removeFavouriteProjectMutation.mutate(projectId);
      setIsFavorite(false);
    } else {
      // Trigger add project to favorites mutation
      addFavouriteProjectMutation.mutate(projectId);
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    // Fetch detailed data for the project when the component mounts
    // You can handle loading, error, and rendering of projectData here
    if (projectData) {
      setData(projectData);
      // console.log("project data testing:", projectData);
    }

    if (commentsData) {
      setCommentData(commentsData);
    }
  }, [projectId, projectData, commentsData]);

  useEffect(() => {
    // Check if user ID is stored in local storage
    const token = localStorage.getItem("token");
    if (!isLoading && !isError && loggedInUserData && token) {
      // Check if the user ID from local storage matches the ID returned from the API
      if (token) {
        // User is logged in
        setIsLogin(true);
      } else {
        // User is not logged in or IDs don't match
        setIsLogin(false);
      }
    }
  }, [loggedInUserData, isLoading, isError]);

  const handleRate = async (rating: number) => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      await rateProject.mutateAsync(rating);
    } catch (error) {
      console.error("Error rating the project:", error);
    } finally {
      setRateToggle(!rateToggle);
    }
  };

  return (
    <>
      {isWeb ? (
        <DashboardLayout>
          <Flex
            mt={"32px"}
            mb={"64px"}
            direction={"column"}
            gap={"24px"}
            maxWidth={"1240px"}
            width={"100%"}
            alignItems={"center"}
            alignSelf={"center"}
          >
            {data?.status === "upcoming" && (
              <ClaimHeader
                projectId={projectId}
                data={data}
                soon
                onClick={handleSoon}
              />
            )}
            {(data?.status === "ongoing" || data?.status === "closing") && (
              <ClaimHeader projectId={projectId} data={data} />
            )}

            {data?.status === "closed" && (
              <ClaimHeader projectId={projectId} data={data} closed />
            )}

            {/* {claimed && <ClaimHeader projectId={projectId} data={data} closed />} */}

            <Flex width={"100%"} justifyContent={"space-between"} mt={"48px"}>
              <DetailCard
                data={data}
                onClick={handleToggleModal}
                isCommentSection={setIsCommentSection}
                user={loggedInUserData?.data}
              />
              <CommentCard
                data={commentData}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
                isLogin={isLogin}
                onClick={handleLogin}
                user={loggedInUserData?.data}
                isLoading={commentsPending}
              />
            </Flex>
          </Flex>
          <Modal size="full" isOpen={isOpen} onClose={handleToggleModal}>
            <ModalOverlay
              bg={"rgba(0, 0, 0, 0.80)"}
              backdropFilter={" blur(2px)"}
            />
            <ModalContent
              bg={"transparent"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              position={"relative"}
            >
              <Box position={"absolute"} top={"24px"} right={"24px"}>
                <IconX size={"60px"} color="#FFF" onClick={handleToggleModal} />
              </Box>
              <Box
                width={"764px"}
                height={"426px"}
                bgImage={data?.detailImage}
                alignSelf={"center"}
                bgRepeat="no-repeat"
                bgPos="center"
                bgSize="contain"
              ></Box>
            </ModalContent>
          </Modal>
        </DashboardLayout>
      ) : (
        <Flex
          pt={"89px"}
          direction={"column"}
          gap={"56px"}
          maxWidth={"1240px"}
          width={"100%"}
          alignItems={"center"}
          alignSelf={"center"}
          position={"relative"}
        >
          {/* like icon and cross */}
          <Box position={"absolute"} top={"24px"} left={"16px"}>
            {/* <BackBtn size={"34px"} arrow onClick={() => navigate("/airdrop")} /> */}
            <BackBtn size={"34px"} arrow onClick={() => navigate(-1)} />
          </Box>
          <Image
            alt={isFavorite ? isFavorite : "dummyIcon"}
            src={isFavorite ? LikedIcon : unlikeIcon}
            width={"34px"}
            height={"34px"}
            position={"absolute"}
            top={"24px"}
            right={"16px"}
            onClick={handleStarClick}
          />
          <ClaimHeaderMobile data={data} />

          <DetailCard
            data={data}
            commentData={commentData}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
            isLoading={commentsPending}
            onClick={handleToggleModal}
            isLogin={isLogin}
            handleLogin={handleLogin}
            isCommentSection={setIsCommentSection}
            user={loggedInUserData?.data}
          />
          {/* picture maximize modal */}
          <Modal isOpen={isOpen} onClose={handleToggleModal}>
            <ModalOverlay
              bg={"rgba(0, 0, 0, 0.80)"}
              backdropFilter={" blur(2px)"}
            />
            <ModalContent
              bg={"transparent"}
              mx={"12px"}
              justifyContent={"center"}
              alignItems={"center"}
              //mt={"150px"}
              position={"relative"}
              height={"100%"}
              //border={"1px solid red"}
            >
              <Box position={"absolute"} top={"-24px"} right={"16px"}>
                <IconX size={"34px"} color="#FFF" onClick={handleToggleModal} />
              </Box>

              <Box alignSelf={"center"}>
                <Image alt="cover" src={data ? data?.detailImage : cover} />
              </Box>
            </ModalContent>
          </Modal>
          {/* rate icon modal */}
          <Modal isOpen={rateToggle} onClose={handleRateToggle}>
            <ModalOverlay
              bg={"rgba(0, 0, 0, 0.80)"}
              backdropFilter={" blur(2px)"}
            />
            <ModalContent
              mx={"12px"}
              justifyContent={"center"}
              alignItems={"center"}
              // mt={"150px"}
              position={"relative"}
            >
              <Box position={"absolute"} top={"-24px"} right={"16px"}>
                <IconX size={"34px"} color="#FFF" onClick={handleRateToggle} />
              </Box>

              <Flex
                alignSelf={"center"}
                height={"100%"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"124px"}
                position={"absolute"}
                top={"250px"}
              >
                <CustomText
                  fontFamily={"Montserrat"}
                  text={"HOW DO YOU LIKE THIS?"}
                  fontSize={"16px"}
                  weight={700}
                  lineHeight={"22.4px"}
                  letterSpacing={"-0.5px"}
                  color={"#FFF"}
                />

                <Flex
                  direction={"column"}
                  gap={"24px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MainBtn
                    br={"100px"}
                    bg
                    text={"LIKE"}
                    icon={thumbUp}
                    iconSize={"28px"}
                    onClick={() => handleRate(1)}
                  />
                  <MainBtn
                    br={"100px"}
                    border
                    text={"DISLIKE"}
                    icon={thumbDown}
                    iconSize={"28px"}
                    color={"#FFF"}
                    onClick={() => handleRate(-1)}
                  />
                </Flex>
              </Flex>
            </ModalContent>
          </Modal>
          {/* Delete Modify Comment modal
          <Modal isOpen={commentToggle} onClose={handleCommentModifyToggle}>
            <ModalOverlay
              bg={"rgba(0, 0, 0, 0.80)"}
              backdropFilter={" blur(2px)"}
            />
            <ModalContent
              bg={"transparent"}
              mx={"12px"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              height={"100%"}
            >
              <Box position={"absolute"} top={"-24px"} right={"16px"}>
                <IconX
                  size={"34px"}
                  color="#FFF"
                  onClick={handleCommentModifyToggle}
                />
              </Box>

              <Flex
                width={"100%"}
                alignSelf={"center"}
                // height={"100%"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"12px"}
                position={"absolute"}
                bottom={"100px"}
              >
                <MainBtn
                  border
                  text={"MODIFY"}
                  icon={editIcon}
                  iconSize={"20px"}
                  color={"#FFF"}
                />
                <MainBtn
                  bg
                  text={"DELETE"}
                  icon={eraseIcon}
                  iconSize={"20px"}
                />
              </Flex>
            </ModalContent>
          </Modal> */}
          <Flex
            width={"100%"}
            p={"12px 12px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"16px"}
            bg={"#1E1E1E"}
            boxShadow={"0px -8px 4px 0px rgba(0, 0, 0, 0.25)"}
            position="sticky"
            bottom="0"
            border={" 1px solid #000"}
            height={"64px"}
            mt={"-56px"}
          >
            {/* open soon */}
            {data?.status === "upcoming" && !isCommentSection && (
              <MainBtn
                color={"#FBFF42"}
                height={"40px"}
                border
                text={"OPEN SOON"}
                //disabled
              />
            )}

            {/* claim or rate */}
            {data?.status === "ongoing" && !isCommentSection && (
              <>
                <Box width={"100%"}>
                  {" "}
                  <MainBtn
                    width={isLessThan370 ? "140px" : "156px"}
                    height={"40px"}
                    bg
                    /*   DONT REMOVE BELOW CODE IT WILL BE USE IN FUTURE

              this below code is commented 
              when client give us some endpoint if  user cliamed or not then this code will work perfectly
              so DONT REMOVE BELOW CODE IT WILL BE USE IN FUTURE 
              */
                    text={data?.isClaimed ? "CLAIMED" : claimText}
                    disabled={
                      data?.isClaimed || claimText === "CLAIMED" ? true : false
                    }
                    onClick={() => handleClaimClick(data?.airdropWebsite)}
                    disColor={"#3e414a"}
                  />
                </Box>
                <MainBtn
                  width={isLessThan370 ? "140px" : "156px"}
                  height={"40px"}
                  border
                  onClick={handleRateToggle}
                  text={data?.isRated ? "RATED" : "RATE"}
                  disabled={data?.isRated ? true : false}
                  disColor={"#3e414a"}
                />{" "}
              </>
            )}

            {/* claim closed */}
            {/* {claimed && !isCommentSection && (
              <MainBtn
                padding={isLessThan370 ? "13px 20px" : "13px 57px"}
                color={"#FFFFFF66"}
                height={"40px"}
                border
                text={"CLAIM CLOSED"}
              />
            )} */}

            {isCommentSection && (
              <>
                {!isLogin && <BeforeLogin onClick={handleLogin} />}
                {isLogin && <AfterLogin user={loggedInUserData?.data} />}
              </>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default DropDetail;

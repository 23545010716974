import { Box, Flex, useMediaQuery, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MainBtn from "../../components/buttons/MainBtn";
import Tag from "../../components/general/Tag";
import profile from "../../assets/icons/other/profile.svg";
import logoutIcon from "../../assets/icons/other/logout.svg";
import like from "../../assets/icons/other/unliked.svg";
import MypageForm from "./MypageForm";
import DashboardLayout from "../../layout/dashboard/DashboardLayout";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";
import { useNavigate } from "react-router-dom";
import { useGetLoggedinUser, useUpdateAccount } from "../../utils/auth.api";
import { useQueryClient } from "@tanstack/react-query";

const MyPage = () => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const { data: loggedInUser } = useGetLoggedinUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [email, setEmail] = useState("");
  const [userName, setuserName] = useState("");
  const [emails, setEmails] = useState([]);

  const updateAccount = useUpdateAccount();
  const toast = useToast();

  useEffect(() => {
    // Extract emails from user data
    const userEmails: any = [];
    if (loggedInUser?.data?.email) {
      userEmails.push("email");
    }
    if (loggedInUser?.data?.googleProfileEmail) {
      userEmails.push("google");
    }
    if (loggedInUser?.data?.facebookProfileEmail) {
      userEmails.push("facebook");
    }
    if (loggedInUser?.data?.appleProfileEmail) {
      userEmails.push("apple");
    }

    // Set the emails state
    setEmails(userEmails);

    // Set individual email and username state
    setEmail(
      loggedInUser?.data?.email ||
        loggedInUser?.data?.googleEmail ||
        loggedInUser?.data?.facebookProfileEmail ||
        loggedInUser?.data?.appleProfileEmail ||
        ""
    );
    setuserName(loggedInUser?.data?.nickname);
  }, [loggedInUser?.data]);

  return (
    <DashboardLayout>
      <Flex
        mt={isWeb ? "40px" : "16px"}
        mb={isWeb ? "64px" : "20px"}
        direction={"column"}
        px={isWeb ? undefined : "16px"}
        gap={"24px"}
        maxWidth={"1240px"}
        width={"100%"}
        alignSelf={"center"}
      >
        {/* tags */}
        <Flex
          alignSelf={"flex-start"}
          gap={"12px"}
          alignItems={"center"}
          w="100%"
          wrap={"wrap"}
          justifyContent={"flex-start"}
        >
          <Tag icon={profile} />
          {loggedInUser && (
            <Tag
              text={"FAVORITES"}
              bg={"#3E414A"}
              icon={like}
              onClick={() => navigate("/mypage-fav")}
            />
          )}
          <Box
            ml={isWeb ? "auto" : "none"}
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("id");
              sessionStorage.clear();
              queryClient.invalidateQueries({ queryKey: ["getToken"] });
              navigate("/");
            }}
          >
            <Tag icon={logoutIcon} text={"Logout"} />
          </Box>
          {/* <Button ml="auto"></Button> */}
        </Flex>

        {/* messages */}
        {/* <Flex
          maxWidth={"1240px"}
          width={"100%"}
          direction={"column"}
          gap={"16px"}
        >
          <Message />
          <Message bg={"#0ABBB0"} text={"Your Profile has been saved."} />
        </Flex> */}

        {/* form */}
        {
          <MypageForm
            {...{
              password,
              setPassword,
              confirmPassword,
              setConfirmPassword,
              email,
              setEmail,
              userName,
              setuserName,
              updateAccount,
              emails,
            }}
          />
        }
        {/* btn  in case of web here and in case of phone btns are in mypage form*/}
        <Flex
          alignSelf={"flex-end"}
          gap={"12px"}
          alignItems={"center"}
          display={isWeb ? "flex" : "none"}
        >
          {" "}
          <MainBtn
            width={"195px"}
            height={"40px"}
            padding={"8px"}
            text={"CANCEL"}
            border
            letterSpacing={"-0.5px"}
            onClick={() => navigate("/")}
          />{" "}
          <MainBtn
            width={"195px"}
            height={"40px"}
            padding={"8px"}
            text={"SAVE"}
            disabled={password !== confirmPassword}
            isLoading={updateAccount.isPending}
            bg
            letterSpacing={"-0.5px"}
            onClick={async () => {
              try {
                await updateAccount.mutateAsync({
                  email: email,
                  password: password,
                  username: userName,
                });
              } catch (error: any) {
                toast({ title: error?.message, status: "error" });
              }
            }}
          />
        </Flex>
      </Flex>
    </DashboardLayout>
  );
};

export default MyPage;

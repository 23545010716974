import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import bg from "../assets/bg/authWebBG.svg";
import bgMobile from "../assets/bg/authMobile.svg";
import bgMobileTest from "../assets/bg/authMobile.png";
import logo from "../assets/logo/main.svg";
import { breakpoint } from "../components/breakpoint-config/Breakpoint";
const AuthLayout = (props: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  return (
    <>
      {" "}
      {isWeb ? (
        <Flex
          align="center"
          justify="space-between"
          maxW="1135px"
          width="100%"
          mx="auto"
        >
          <Flex
            height="100vh"
            maxW="610px"
            w="100%"
            backgroundImage={bg}
            backgroundSize="cover"
            backgroundRepeat={"no-repeat"}
            bgPos={"center"}
            alignItems="center"
            justifyContent="center"
          >
            <Image src={logo} width="480px" height="120px" />
          </Flex>

          <Box maxW="400px" width="100%">
            {props?.children}
          </Box>
        </Flex>
      ) : (
        <Flex
          width={"100%"}
          minH={"100vh"}
          // backgroundImage={bgMobile}
          backgroundImage={bgMobileTest}
          bgSize={"cover"}
          backgroundRepeat={"no-repeat"}
        >
          <Flex
            width={"100%"}
            mx={"auto"}
            direction={"column"}
            gap={"32px"}
            p={"24px 16px"}
            minH={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
            // bg={" rgba(0, 0, 0, 0.25)"}
            // =  mixBlendMode={"overlay"}
            // backdropFilter={"blur(2px)"}
            position={"relative"}
          >
            <Image src={logo} width="165px" height="42px" />
            <Box maxW="400px" width="100%">
              {props?.children}
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AuthLayout;

import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CardTabs from "../CardTabs";
import CommentCardContent from "./CommentCardContent";

const CommentCard = ({
  data,
  isLogin,
  onClick,
  user,
  setSortOrder,
  sortOrder,
  isLoading,
}: any) => {
  // useEffect(() => {}, [data]);

  return (
    <Flex
      maxWidth={"610px"}
      p={"32px 24px"}
      direction={"column"}
      gap={"24px"}
      borderRadius={"0px 8px 0px 0px"}
      bg={"#3E414A"}
      width={"100%"}
      position={"relative"}
    >
      {/* here is the tab */}
      <Flex
        gap={"12px"}
        alignItems={"center"}
        position={"absolute"}
        top={"-39px"}
        left={0}
      >
        <CardTabs text={"COMMENT"} isActive />
      </Flex>
      <CommentCardContent
        data={data}
        isLogin={isLogin}
        onClick={onClick}
        user={user}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        isLoading={isLoading}
      />
    </Flex>
  );
};

export default CommentCard;

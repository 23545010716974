import React from "react";

export const News = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 0H10.5C7.743 0 5.5 2.243 5.5 5V6H5C2.519 6 0.5 8.019 0.5 10.5V20.5C0.5 22.429 2.069 23.999 3.999 24H19.5C22.257 24 24.5 21.757 24.5 19V5C24.5 2.243 22.257 0 19.5 0ZM5.5 20.5C5.5 21.327 4.827 22 4 22C3.173 22 2.5 21.327 2.5 20.5V10.5C2.5 9.122 3.622 8 5 8H5.5V20.5ZM22.5 19C22.5 20.654 21.154 22 19.5 22H7.162C7.378 21.545 7.5 21.037 7.5 20.5V5C7.5 3.346 8.846 2 10.5 2H19.5C21.154 2 22.5 3.346 22.5 5V19ZM20.5 7C20.5 7.552 20.052 8 19.5 8H16.5C15.948 8 15.5 7.552 15.5 7C15.5 6.448 15.948 6 16.5 6H19.5C20.052 6 20.5 6.448 20.5 7ZM20.5 11C20.5 11.552 20.052 12 19.5 12H10.5C9.948 12 9.5 11.552 9.5 11C9.5 10.448 9.948 10 10.5 10H19.5C20.052 10 20.5 10.448 20.5 11ZM20.5 15C20.5 15.552 20.052 16 19.5 16H10.5C9.948 16 9.5 15.552 9.5 15C9.5 14.448 9.948 14 10.5 14H19.5C20.052 14 20.5 14.448 20.5 15ZM20.5 19C20.5 19.552 20.052 20 19.5 20H10.5C9.948 20 9.5 19.552 9.5 19C9.5 18.448 9.948 18 10.5 18H19.5C20.052 18 20.5 18.448 20.5 19ZM9.5 7V5C9.5 4.448 9.948 4 10.5 4H12.5C13.052 4 13.5 4.448 13.5 5V7C13.5 7.552 13.052 8 12.5 8H10.5C9.948 8 9.5 7.552 9.5 7Z"
        fill="white"
        fill-opacity="0.6"
      />
    </svg>
  );
};

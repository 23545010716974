import { Box, Spinner } from "@chakra-ui/react";
import theme from "../theme/theme";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loginby from "../pages/auth/login/LoginBy";
import Login from "../pages/auth/login/Login";
import Signup from "../pages/auth/singup/Signup";
import SignupBy from "../pages/auth/singup/SignupBy";
import NotFound from "../pages/auth/error404/NotFound";
import AirDrop from "../pages/airdrop/AirDrop";
import Contact from "../pages/contact/Contact";
import MyPage from "../pages/mypage/MyPage";
import MypageFavourite from "../pages/mypage/MypageFavourite";
import DropDetail from "../pages/airdrop/airdrop-detail/DropDetail";
import DeleteConfirmation from "../pages/mypage/account-delete/DeleteConfirmation";
import AccountDeleted from "../pages/mypage/account-delete/AccountDeleted";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { app } from "../firebaseConfig";

const routes = [
  {
    path: "/",

    Component: AirDrop,
  },
  {
    path: "/login",
    Component: Loginby,
    isAuth: true,
  },
  {
    path: "/login/email",

    Component: Login,
    isAuth: true,
  },
  {
    path: "/signup",

    Component: SignupBy,
    isAuth: true,
  },
  {
    path: "/signup/email",

    Component: Signup,
    isAuth: true,
  },
  {
    path: "/404",

    Component: NotFound,
    isPrivate: false,
  },
  // {
  //   path: "/airdrop",

  //   Component: AirDrop,
  //   // isPrivate: true,
  // },
  {
    path: "/airdrop-detail/:projectId",

    Component: DropDetail,
    // isPrivate: true,
  },

  {
    path: "/contact",

    Component: Contact,
    isPrivate: true,
  },

  {
    path: "/mypage",

    Component: MyPage,
    isPrivate: true,
  },
  {
    path: "/mypage-fav",

    Component: MypageFavourite,
    isPrivate: true,
  },
  {
    path: "/account-delete",

    Component: DeleteConfirmation,
    isPrivate: true,
  },
  {
    path: "/account-delete-success",

    Component: AccountDeleted,
    isPrivate: false,
  },
];

export default function AppRoutes() {
  const bgColor = theme.colors.bg;
  const { data: token, isPending } = useQuery({
    queryKey: ["getToken"],
    queryFn: () => {
      const response = localStorage.getItem("token");
      return response;
    },
  });

  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("Location changed");
    queryClient.invalidateQueries({ queryKey: ["getToken"] });
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    app; // Ensure Firebase is initialized
  }, []);

  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate, isAuth }) => (
        <Route
          key={path}
          element={
            <Box bg={bgColor}>
              {isPending ? (
                <Spinner color="white" size={"xl"} />
              ) : isPrivate ? (
                token ? (
                  <Component />
                ) : (
                  <Navigate to="/" />
                )
              ) : isAuth && token ? (
                <Navigate to="/" />
              ) : (
                <Component />
              )}
            </Box>
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

import { Box, Divider, Flex, Image } from "@chakra-ui/react";
import CustomText from "../../../../text/customText";
import { IconArrowsMaximize } from "@tabler/icons-react";
import { useEffect } from "react";

const Detail = ({ data, onClick }: any) => {
  const plainText = `<span style="font-family: Montserrat; color: #FFF;">${data?.projectDescription}</span>`;

  useEffect(() => {}, [data]);

  return (
    <Flex gap={"24px"} direction={"column"} height={"100%"}>
      {data?.detailImage && (
        <Box
          position={"relative"}
          w="100%"
          h="100%"
          minW={{ base: "260px", md: "560px" }}
          minH={{ base: "260px", md: "315px" }}
          maxW={{ base: "260px", md: "560px" }}
          maxH={{ base: "260px", md: "315px" }}
          bgImage={`url(${data?.detailImage})`}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgPos="center"
        >
          <Box
            width={"24px"}
            height={"24px"}
            position={"absolute"}
            top={"12px"}
            right={"12px"}
            cursor={"pointer"}
            onClick={onClick}
          >
            <IconArrowsMaximize
              size={"24px"}
              color="rgba(255, 255, 255, 0.67)"
            />
          </Box>
        </Box>
      )}

      {/* <CustomText
        fontFamily={"Montserrat"}
        text={data?.projectDescription || ""}
        // text={"some dummy text"}
        fontSize={"16px"}
        weight={400}
        lineHeight={"22.4px"}
        letterSpacing={"-0.5px"}
        color={"#FFF"}
      /> */}

      {plainText ? (
        <div dangerouslySetInnerHTML={{ __html: plainText }}></div>
      ) : (
        ""
      )}

      {/* links */}
      <Flex direction={"column"} gap={"16px"} width={"100%"}>
        <Flex gap={"8px"} alignItems={"center"}>
          <Divider
            colorScheme={"whiteAlpha"}
            size={"255px"}
            orientation="horizontal"
          />
          <CustomText
            fontFamily={"Montserrat"}
            text={"LINKS"}
            fontSize={"16px"}
            weight={700}
            lineHeight={"22.4px"}
            letterSpacing={"-0.5px"}
            color={"#FFF"}
          />
          <Divider
            colorScheme={"whiteAlpha"}
            size={"255px"}
            orientation="horizontal"
          />
        </Flex>

        <Flex gap={"15px"} alignItems={"center"} wrap={"wrap"}>
          {data?.projectLinks?.map((link: any, index: number) => (
            <a href={`${link?.url}`} target="_blank" rel="noopener noreferrer">
              <Image
                key={index}
                alt={link.name}
                src={link.logoImage}
                width={"40px"}
                cursor={"pointer"}
                aspectRatio="3/2"
                objectFit="contain"
              />
            </a>

            // <Image
            //   key={index}
            //   alt={link.name}
            //   src={link.logoImage}
            //   // sizes="40px"
            //   width={"40px"}
            //   height={"40px"}
            //   cursor={"pointer"}
            //   onClick={()=>href={link?.name}}
            // />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Detail;

import { Input } from "@chakra-ui/react";

function FormInput({
  border,
  bg,
  h,
  mt,
  w,
  br,
  type,
  placeholder,
  fontstyle,
  placeholderWeight,
  onChange,
  value,
  disabled,
}: any) {
  return (
    <Input
      p={"8px 16px"}
      onChange={onChange}
      value={value}
      minH={h ? h : "48px"}
      width={w ? w : "100%"}
      height={h}
      mt={mt ? mt : "4px"}
      type={type}
      placeholder={placeholder}
      //focusBorderColor={""}
      border={border ? border : "1px solid #FFF"}
      bg={bg ? bg : "rgba(0, 0, 0, 0.60)"}
      borderRadius={br ? br : "8px"}
      fontFamily="Noto Sans"
      textColor={"#FFF"}
      _placeholder={{
        fontStyle: fontstyle ? fontstyle : "italic",
        fontSize: "12px",
        fontWeight: placeholderWeight ? placeholderWeight : 700,
      }}
      isDisabled={disabled}
    />
  );
}

export default FormInput;

import { Avatar, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/customText";
import { IconChevronDown } from "@tabler/icons-react";
import placeholder from "../../assets/icons/social/placeholder.svg";
import { breakpoint } from "../breakpoint-config/Breakpoint";

const AuthOption = ({ text, icon, isGoogle, onClick }: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  return (
    <Flex
      maxWidth={"400px"}
      height={"60px"}
      width={"100%"}
      p={"8px 16px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"8px"}
      border={"1px solid #FFF"}
      bg={"rgba(0, 0, 0, 0.60)"}
      cursor={"pointer"}
      onClick={onClick}
    >
      {isGoogle && (
        <Flex gap={"8px"} alignItems={"center"} justifyContent={"center"}>
          <Avatar
            src={localStorage.getItem("googleProfileImage") || placeholder}
            name={localStorage.getItem("nickname") || "John Doe"}
            size="sm"
          />
          <Flex direction={"column"} justifyContent={"flex-start"}>
            <CustomText
              fontFamily={"Noto Sans"}
              text={text}
              fontSize={isWeb ? "16px" : "14px"}
              weight={700}
              lineHeight={isWeb ? "16px" : "14px"}
              letterSpacing={"-0.5px"}
            />

            <Flex gap={"2px"} alignItems={"center"} justifyContent={"center"}>
              {" "}
              <CustomText
                fontFamily={"Noto Sans"}
                text={
                  localStorage.getItem("googleProfileEmail") ||
                  "johndoe@gmail.com"
                }
                fontSize={"14px"}
                weight={400}
                lineHeight={"16px"}
                letterSpacing={"-0.5px"}
              />
              <IconChevronDown size={"18px"} color="#FFF" />
            </Flex>
          </Flex>
        </Flex>
      )}
      {!isGoogle && (
        <CustomText
          fontFamily={"Noto Sans"}
          text={text}
          fontSize={isWeb ? "16px" : "14px"}
          weight={700}
          lineHeight={isWeb ? "16px" : "14px"}
          letterSpacing={"-0.5px"}
        />
      )}

      <Image src={icon} sizes="24px 24px" />
    </Flex>
  );
};

export default AuthOption;

import { Heading } from "@chakra-ui/react";
import React from "react";
import theme from "../../theme/theme";

const AuthHeading = ({ title }: any) => {
  return (
    <Heading
      textAlign="center"
      fontWeight="700"
      fontSize="24px"
      color={theme.colors.heading}
      letterSpacing={"-0.5px"}
      lineHeight={"24px"}
    >
      {title}
    </Heading>
  );
};

export default AuthHeading;

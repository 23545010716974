import { useQuery } from "@tanstack/react-query";
import { client } from "./api.client";

export const useGetAds = (limit: number, offset: number) =>
  useQuery({
    queryKey: ["getAds"],
    queryFn: async () => {
      const response = await client(
        `ad/getAdsForWeb?limit=${limit}&offset=${offset}`
      );
      return response;
    },
  });

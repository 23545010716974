import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import Data from "./Data";
import { breakpoint } from "../../breakpoint-config/Breakpoint";
import { useGetProjectsCount } from "../../../utils/airdrop.api";

const Stats = ({ handleTab, activeTab }: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);
  const [isLessThan767] = useMediaQuery(`(max-width: 767px)`);

  // Fetch project counts data using the custom hook
  const { data, isLoading, isError } = useGetProjectsCount();

  if (isLoading) {
    return <div>Loading...</div>; // Render loading state while data is being fetched
  }

  if (isError) {
    return <div>Error fetching data</div>; // Render error message if data fetching fails
  }

  // Check if data is defined before destrcuturing
  if (!data) {
    return <div>No data available</div>; // Render message if data is undefined
  }

  // Destructure data object to access project counts
  const { ongoingProjectCount, closedProjectCount, openSoon, closeSoon } = data;

  const handleTabClick = (tabName: string) => {
    if (activeTab === tabName) {
      handleTab("");
    } else {
      handleTab(tabName); // Call the passed handleTab function
    }
  };

  return (
    <Flex
      maxWidth={"1240px"}
      p={isWeb ? "16px 0px" : "15px 5px"}
      width={"100%"}
      gap={isLessThan767 ? "18px" : "32px"}
      bg={"#1E1E1E"}
      boxShadow="inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      alignItems={"flex-end"}
      justifyContent={"center"}
    >
      <Box onClick={() => handleTabClick("closing")}>
        <Data
          title={"CLOSE SOON"}
          stats={closeSoon}
          isActive={activeTab === "closing"}
        />
      </Box>

      <Box onClick={() => handleTabClick("ongoing")}>
        <Data
          title={"ONGOING"}
          stats={ongoingProjectCount}
          isActive={activeTab === "ongoing"}
        />
      </Box>

      <Box onClick={() => handleTabClick("upcoming")}>
        <Data
          title={"OPEN SOON"}
          stats={openSoon}
          isActive={activeTab === "upcoming"}
        />
      </Box>

      <Box onClick={() => handleTabClick("closed")}>
        <Data
          title={"CLOSED"}
          stats={closedProjectCount}
          isActive={activeTab === "closed"}
        />
      </Box>
    </Flex>
  );
};

export default Stats;

import React from "react";

export const ContactActive = () => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 0H9.5C6.74 0 4.5 2.24 4.5 5V13C4.5 15.76 6.74 18 9.5 18H19.5C22.26 18 24.5 15.76 24.5 13V5C24.5 2.24 22.26 0 19.5 0ZM9.5 2H19.5C20.39 2 21.19 2.39 21.74 3L16.62 8.12C15.45 9.29 13.55 9.29 12.38 8.12L7.26 3C7.81 2.38 8.61 2 9.5 2ZM19.5 16H9.5C7.85 16 6.5 14.65 6.5 13V5.07L10.96 9.53C11.93 10.5 13.22 10.99 14.5 10.99C15.78 10.99 17.06 10.5 18.04 9.53L22.5 5.07V13C22.5 14.65 21.15 16 19.5 16ZM19.5 21C19.5 21.55 19.05 22 18.5 22H5.5C2.74 22 0.5 19.76 0.5 17V6C0.5 5.45 0.95 5 1.5 5C2.05 5 2.5 5.45 2.5 6V17C2.5 18.65 3.85 20 5.5 20H18.5C19.05 20 19.5 20.45 19.5 21Z"
        fill="white"
      />
    </svg>
  );
};

import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import yolo from "../../../assets/icons/table/yolo.png";
import CustomText from "../../text/customText";
import unliked from "../../../assets/icons/other/unliked.svg";
import liked from "../../../assets/icons/other/liked.svg";
import solanaIco from "../../../assets/icons/other/solana.png";
import { IconThumbUp } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import diamond from "../../../assets/icons/tag-icons/diamond.svg";
import { format } from "date-fns";
import { useClickProject } from "../../../utils/airdrop.api";
const DropCard = ({ data, color }: any) => {
  const navigate = useNavigate();
  const updateRateClick = useClickProject();

  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);

  const startDate = new Date(data?.airdropStartTime);
  const endDate = new Date(data?.airdropEndTime);

  let status;
  console.log("status data we're getting", data?.status);
  if (data?.status === "upcoming") {
    status = "OPEN SOON";
  } else if (data?.status === "closed") {
    status = "CLOSED";
  } else if (data?.status === "ongoing") {
    status = "ONGOING";
  } else if (data?.status === "closing") {
    status = "CLOSING";
  }

  let statusBackgroundColor;
  switch (status) {
    case "ONGOING":
      statusBackgroundColor = "#DD1B78";
      break;
    case "CLOSING":
      statusBackgroundColor = "#0ABBB0";
      break;
    case "OPEN SOON":
      statusBackgroundColor = "#FBFF42";
      break;
    case "CLOSED":
      statusBackgroundColor = "#FFF";
      break;
    default:
      statusBackgroundColor = "#FFF";
      break;
  }

  return (
    <Flex
      onClick={() => {
        updateRateClick?.mutateAsync(data?._id || data?.id);
        navigate(`/airdrop-detail/${data._id}`);
      }}
      width={"100%"}
      p={" 10px 14px 14px 14px"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"16px"}
      borderRadius={"8px"}
      bg={" #1E1E1E"}
    >
      <Image
        alt={data?.profilePictureUrl ? data.profilePictureUrl : "DummyLogo"}
        src={data?.profilePictureUrl ? data.profilePictureUrl : yolo}
        width={isLessThan370 ? "40px" : "52px"}
        height={isLessThan370 ? "40px" : "52px"}
      />

      <Flex direction={"column"} gap={"8px"} width={"100%"}>
        {/* title and like icon */}
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <CustomText
            text={data?.name ? data.name : "dummy Name"}
            color={"#FFF"}
            size={"16px"}
            weight={500}
            fontFamily={"Montserrat"}
            h={"16px"}
            letterSpacing={"-0.5px"}
          />

          {data?.isFavorite ? (
            <Image src={liked} alt="liked" boxSize="20px" cursor={"pointer"} />
          ) : (
            <Image
              src={unliked}
              alt="unliked"
              boxSize="20px"
              cursor={"pointer"}
            />
          )}
        </Flex>

        {/* tags */}
        <Flex gap={"8px"} alignItems={"center"}>
          <Flex
            borderRadius={"100px"}
            bg={"#3E414A"}
            height={"18px"}
            // width={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"4px"}
            p={"3px 4px"}
          >
            <Image
              src={data?.projectType ? data.projectType?.icon : solanaIco}
              alt={data?.projectType ? data.projectType?.title : "Dummmy icon"}
              width={isLessThan370 ? "12px" : "14px"}
              height={isLessThan370 ? "12px" : "14px"}
            />
            <CustomText
              text={data?.projectType ? data.projectType?.title : "dummy type"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              align={"center"}
              size={isLessThan370 ? "10px" : "12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
          </Flex>

          <Flex
            borderRadius={"100px"}
            bg={"#3E414A"}
            height={"18px"}
            // width={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"4px"}
            p={"3px 4px"}
          >
            <Image src={diamond} boxSize={"12px"} />
            <CustomText
              text={data ? data.rewards : "20K dummy"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              align={"center"}
              size={isLessThan370 ? "10px" : "12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
          </Flex>

          <Flex
            borderRadius={"100px"}
            bg={"#3E414A"}
            height={"18px"}
            // width={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"4px"}
            p={"3px 4px"}
          >
            <IconThumbUp color="#FFF" size={isLessThan370 ? "12px" : "14px"} />

            <CustomText
              text={data ? data.positiveRatingCount : "+1k-dummy"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              align={"center"}
              size={isLessThan370 ? "10px" : "12px"}
              weight={isLessThan370 ? 600 : 700}
              h={"11px"}
              letterSpacing={"-0.5"}
            />
          </Flex>
        </Flex>

        {/* date and status */}
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Box width={"100%"} minWidth={"112px"}>
            <CustomText
              text={`${format(new Date(startDate), "MMM/dd/yyyy")} ~ ${
                endDate ? format(new Date(endDate), "MMM/dd/yyyy") : "N/A"
              }`}
              //  text={`${data?.airdropStartTime} ~ ${data?.airdropEndTime}`}
              color={"#999999"}
              fontFamily={"Montserrat"}
              // align={"center"}
              size={"12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5"}
              wrap={"nowrap"}
            />
          </Box>

          <Flex
            bg={statusBackgroundColor ? statusBackgroundColor : "#0ABBB0"}
            borderRadius={"100px"}
            maxWidth={"110px"}
            width={"100%"}
            p={"2px 4px"}
            height={"18px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CustomText
              text={status ? status : "dummy"}
              color={"#000"}
              size={isLessThan370 ? "12px" : "14px"}
              weight={700}
              fontFamily={"Montserrat"}
              h={"14px"}
              letterSpacing={"-0.5px"}
              wrap={"nowrap"}
              align="center"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DropCard;

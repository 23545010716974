import React from "react";
import AuthLayout from "../../../layout/AuthLayout";
import { Flex, Image } from "@chakra-ui/react";
import MainBtn from "../../../components/buttons/MainBtn";
import er404 from "../../../assets/icons/other/er404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"32px"}
        width={"100%"}
      >
        <Image src={er404} height={"152px"} width={"152px"} />

        <p
          style={{
            color: "#FFF",
            fontFamily: "Montserrat",
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "38.4px",
            letterSpacing: "-0.5px",
          }}
        >
          <span> THE PAGE</span> <br />
          YOU’RE LOOKING FOR COULD NOT BE FOUND.
        </p>
        <MainBtn text={"RETURN HOME"} bg onClick={() => navigate("/")} />
      </Flex>
    </AuthLayout>
  );
};

export default NotFound;

import { Text } from "@chakra-ui/react";
import React from "react";
import theme from "../../theme/theme";

const FormLabel = ({ h, weight, size, label, mt, labelColor }: any) => {
  return (
    <Text
      mt={mt}
      fontFamily="Noto Sans"
      color={labelColor ? labelColor : theme.colors.heading}
      fontWeight={weight ? weight : "700"}
      fontSize={size ? size : "14px"}
      lineHeight={h ? h : "14px"}
      letterSpacing={"-0.5px"}
    >
      {label}
    </Text>
  );
};

export default FormLabel;

import { Box, Flex, useMediaQuery, useToast } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/customText";
import FormLabel from "../../components/form-inputs-label-select/FormLabel";
import FormInput from "../../components/form-inputs-label-select/FormInput";
import MainBtn from "../../components/buttons/MainBtn";
import { useNavigate } from "react-router-dom";
import { breakpoint } from "../../components/breakpoint-config/Breakpoint";

const MypageForm = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  email,
  setEmail,
  userName,
  setuserName,
  updateAccount,
  emails,
}: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);

  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Flex
      maxWidth={"1240px"}
      width={"100%"}
      p={isWeb ? "16px" : "0px"}
      alignItems={isWeb ? "flex-start" : "center"}
      // gap={isWeb ? "21px" : "16px"}
      gap={isWeb ? undefined : "16px"}
      justifyContent={isWeb ? "space-between" : undefined}
      borderRadius={isWeb ? "12px" : undefined}
      bg={isWeb ? "#1E1E1E" : undefined}
      direction={isWeb ? "row" : "column"}
    >
      {/* Connect */}

      {/* <Flex
        p={isWeb ? "0px" : "12px 8px 16px 8px"}
        maxWidth={"385px"}
        width={"100%"}
        direction={"column"}
        gap={isWeb ? "33px" : "12px"}
        borderRadius={isWeb ? undefined : "8px"}
        bg={isWeb ? undefined : "#1E1E1E"}
      >
        <CustomText
          text={"Connect"}
          color={"#FFF"}
          size={"20px"}
          weight={700}
          fontFamily={"Noto Sans"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
        <Flex direction={"column"} gap={"12px"}>
          <AuthOption
            onClick={handleGoogleLinkClick}
            text={
              emails?.includes("google")
                ? "Registered with Google"
                : "Connect With Google"
            }
            icon={gooogleIcon}
          />
          <AuthOption
            onClick={handleFacebookLinkClick}
            text={
              emails?.includes("facebook")
                ? "Registered with Facebook"
                : "Connect With Facebook"
            }
            icon={fbIcon}
          />
          <AuthOption
            onClick={handleAppleLinkClick}
            text={
              emails?.includes("apple")
                ? "Registered with Apple"
                : "Connect With Apple"
            }
            icon={appleIcon}
            // onClick={() => navigate("/login/email")}
          />
        </Flex>
      </Flex> */}
      {/* Account */}
      <Flex
        p={isWeb ? "0px" : "12px 8px 16px 8px"}
        maxWidth={"385px"}
        width={"100%"}
        direction={"column"}
        gap={isWeb ? "15px" : "12px"}
        borderRadius={isWeb ? undefined : "8px"}
        bg={isWeb ? undefined : "#1E1E1E"}
      >
        <CustomText
          text={"Account "}
          color={"#FFF"}
          size={"20px"}
          weight={700}
          fontFamily={"Noto Sans"}
          h={"20px"}
          letterSpacing={"-0.5px"}
        />
        <Flex direction={"column"} gap={"12px"}>
          <Box>
            <FormLabel label={"Username"} />
            <FormInput
              type={"text"}
              value={userName}
              onChange={(e: any) => setuserName(e.target.value)}
            />
          </Box>
          <Box>
            <FormLabel label={"Email"} />
            <FormInput
              type={"email"}
              value={email}
              // onChange={(e: any) => setEmail(e.target.value)}
              disabled
            />
          </Box>

          <Box>
            <FormLabel label={"Password"} />
            <FormInput
              type={"password"}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </Box>
          <Box>
            <FormLabel label={"Confirm Password"} />
            <FormInput
              type={"password"}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
            />
          </Box>
        </Flex>
      </Flex>

      {/* btn in mobile view  start*/}
      <Flex
        alignSelf={"center"}
        gap={"12px"}
        alignItems={"center"}
        mt={"16px"}
        display={isWeb ? "none" : "flex"}
      >
        {" "}
        <MainBtn
          width={isLessThan370 ? "140px" : "156px"}
          height={"40px"}
          padding={"8px"}
          text={"CANCEL"}
          border
          letterSpacing={"-0.5px"}
        />{" "}
        <MainBtn
          width={isLessThan370 ? "140px" : "156px"}
          height={"40px"}
          padding={"8px"}
          text={"SAVE"}
          disabled={password !== confirmPassword}
          isLoading={updateAccount.isPending}
          bg
          letterSpacing={"-0.5px"}
          onClick={async () => {
            try {
              await updateAccount.mutateAsync({
                email: email,
                password: password,
                username: userName,
              });
            } catch (error: any) {
              toast({ title: error?.message, status: "error" });
            }
          }}
        />
      </Flex>
      {/* btn in mobile view end */}

      {/* terms and policy */}
      <Flex
        direction={"column"}
        justifyContent={isWeb ? "space-between" : undefined}
        gap={isWeb ? undefined : "16px"}
        h={"429px"}
        mt={isWeb ? undefined : "16px"}
      >
        <Box>
          <p
            style={{
              color: "#a5a5a5",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "25.6px",
              letterSpacing: "-0.5px",
              width: isWeb ? "550px" : "100%",
            }}
          >
            Blockvestor will process your data to send you information about our
            products and services, promotions, surveys, raffles, based on our
            legitimate interest, and updates from the creators you follow, if
            you have consented to this. Your data will not be disclosed to third
            parties. They will be communicated outside the EU under the terms of
            the{" "}
            <span
              style={{
                color: "#a5a5a5",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "160%",
                letterSpacing: "-0.5px",
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              privacy policy
            </span>{" "}
            and
            <span
              style={{
                color: "#a5a5a5",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "160%",
                letterSpacing: "-0.5px",
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              {" "}
              TERMS OF USE
            </span>
          </p>
        </Box>

        <CustomText
          onClick={() => navigate("/account-delete")}
          cursor={"pointer"}
          text={"delete account"}
          color={"rgba(255, 255, 255, 0.60)"}
          size={"14px"}
          weight={300}
          fontFamily={"Noto Sans"}
          h={"14px"}
          letterSpacing={"-0.5px"}
          align={"right"}
          textDecoration={"underline"}
        />
      </Flex>
    </Flex>
  );
};

export default MypageForm;

import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import yolo from "../../assets/icons/other/yolo.svg";
import liked from "../../assets/icons/other/liked.svg";
import CustomText from "../text/customText";
import solanaIco from "../../assets/icons/other/solana.png";
import unliked from "../../assets/icons/other/unliked.svg";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
const DropFavouriteCard = ({ data }: any) => {
  const containerStyle: React.CSSProperties = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    whiteSpace: "normal",
    fontFamily: "Montserrat",
    color: "#FFF",
  };

  const [isLessThan370] = useMediaQuery(`(max-width: 370px)`);
  const navigate = useNavigate();

  const startDate = new Date(data?.airdropStartTime);
  const endDate = new Date(data?.airdropEndTime);
  let status;
  console.log("status data we're getting", data?.status);
  if (data?.status === "upcoming") {
    status = "OPEN SOON";
  } else if (data?.status === "closed") {
    status = "CLOSED";
  } else if (data?.status === "ongoing") {
    status = "ONGOING";
  } else if (data?.status === "closing") {
    status = "CLOSING";
  }

  let statusBackgroundColor;
  switch (status) {
    case "ONGOING":
      statusBackgroundColor = "#DD1B78";
      break;
    case "CLOSING":
      statusBackgroundColor = "#0ABBB0";
      break;
    case "OPEN SOON":
      statusBackgroundColor = "#FBFF42";
      break;
    case "CLOSED":
      statusBackgroundColor = "#FFF";
      break;
    default:
      statusBackgroundColor = "#FFF";
      break;
  }

  return (
    <Flex
      onClick={() => {
        navigate(`/airdrop-detail/${data._id}`);
      }}
      width={"100%"}
      p={" 10px 14px 14px 14px"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"16px"}
      borderRadius={"8px"}
      bg={" #1E1E1E"}
    >
      {/* logo and fav icon */}
      <Flex direction={"column"} gap={"3px"} alignItems={"center"}>
        <Image
          alt="logo"
          src={data?.profilePictureUrl ? data.profilePictureUrl : "DummyLogo"}
          width={"52px"}
          height={"52px"}
        />

        <Image
          alt="logo"
          src={data?.isFavorite ? liked : unliked}
          width={"24px"}
          height={"24px"}
        />
      </Flex>

      <Flex direction={"column"} gap={"8px"} width={"100%"}>
        {/* title and tag */}
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <CustomText
            text={data?.name ? data.name : "dummy Name"}
            color={"#FFF"}
            size={"16px"}
            weight={500}
            fontFamily={"Montserrat"}
            h={"16px"}
            letterSpacing={"-0.5px"}
          />
          <Flex
            borderRadius={"100px"}
            bg={"#3E414A"}
            height={"18px"}
            // width={"25px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"4px"}
            p={"3px 4px"}
          >
            <Image
              src={data?.projectType ? data.projectType.icon : solanaIco}
              alt="icon"
              width={"14px"}
              height={"14px"}
            />
            <CustomText
              text={data?.projectType ? data.projectType.title : "type"}
              color={"#FFF"}
              fontFamily={"Montserrat"}
              align={"center"}
              size={"12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
          </Flex>
        </Flex>

        {/* date and status */}
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Box width={"100%"} minWidth={"109px"}>
            <CustomText
              text={`${format(new Date(startDate), "MMM/dd/yyyy")} ~ ${
                endDate ? format(new Date(endDate), "MMM/dd/yyyy") : "N/A"
              }`}
              color={"#999999"}
              fontFamily={"Montserrat"}
              // align={"center"}
              size={"12px"}
              weight={400}
              h={"12px"}
              letterSpacing={"-0.5"}
            />
          </Box>

          <Box width={"100%"}>
            <CustomText
              text={status ? status : "dummy"}
              color={statusBackgroundColor ? statusBackgroundColor : "#0ABBB0"}
              size={isLessThan370 ? "12px" : "14px"}
              weight={700}
              fontFamily={"Montserrat"}
              h={"14px"}
              letterSpacing={"-0.5px"}
              wrap={"nowrap"}
              align={"right"}
            />
          </Box>
        </Flex>

        <CustomText
          text={data?.projectDescription || " "}
          color={"#FFF"}
          fontFamily={"Montserrat"}
          align={"justify"}
          size={"12px"}
          weight={2300}
          h={"14.4px"}
          letterSpacing={"-0.5"}
          wrap={"wrap"}
        />
      </Flex>
    </Flex>
  );
};

export default DropFavouriteCard;

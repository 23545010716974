import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";

//getting list of all active airdrops
export const useGetAirdrops = (
  limit = 5,
  offset = 0,
  searchQuery = "",
  status = "",
  columnName: string,
  columnOrder: string,
  isFavorites: string
) =>
  useQuery({
    queryKey: [
      "useGetAirdrops",
      limit,
      offset,
      searchQuery,
      status,
      columnName,
      columnOrder,
      isFavorites,
    ],
    queryFn: async () => {
      const response = await client(
        `project/getActiveProjects?limit=${limit}&offset=${offset}&searchQuery=${searchQuery}&status=${status}&columnName=${columnName}&columnOrder=${columnOrder}&isFavorites=${isFavorites}`
      );
      return response;
    },
  });

//get airdrop by id
export const useGetAirdropById = (airdropId: string) =>
  useQuery({
    queryKey: ["useGetAirdropById", airdropId],
    queryFn: async () => {
      const response = await client(`project/getProjectById/${airdropId}`);
      return response;
    },
  });

//// projects count status \\\

export const useGetProjectsCount = () =>
  useQuery({
    queryKey: ["useGetProjectsCount"],
    queryFn: async () => {
      const response = await client(`project/getProjectCounts`);
      return response;
    },
  });

///Get  favourite project list
export const useGetFavouriteAirdrops = (limit = 5, offset = 0) =>
  useQuery({
    queryKey: ["useGetFavouriteAirdrops", limit, offset],
    queryFn: async () => {
      const response = await client(
        `project/getFavoriteProjects?limit=${limit}&offset=${offset}`
      );
      return response;
    },
  });

// Add project to favorites
export const useAddFavouriteProject = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client(
        `project/addProjectToFavorites/${projectId}`,
        {
          method: "POST",
        }
      );

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["useGetAirdropById", projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ["useGetAirdropById", projectId],
        });
      }

      return response;
    },
  });
};

// Remove project from favorites
export const useRemoveFavouriteProject = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client(
        `project/removeProjectFromFavorites/${projectId}`,
        {
          method: "POST",
        }
      );

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["useGetAirdropById", projectId],
        });
      }

      return response;
    },
  });
};

//claim project by projectID

export const useClaimById = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client(`project/claimAirdrop/${projectId}`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["useGetAirdropById", projectId],
        });
      }

      return response;
    },
  });
};

//Rate project by projectID
export const useRateById = (projectId: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (rating: number) => {
      const response = await client(
        `project/rateProject/${projectId}?rating=${rating}`,
        {
          method: "POST",
        }
      );

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["useGetAirdropById", projectId],
        });
      }

      return response;
    },
  });
};

export const useClickProject = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (projectId: string) => {
      const response = await client(`project/clickProject/${projectId}`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    },
  });
};

export const useClickAd = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (id: string) => {
      const response = await client(`project/clickAd/${id}`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    },
  });
};

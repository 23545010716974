import React from "react";

export const Airdrop = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M20.5 7H18.762C19.3274 6.5016 19.7762 5.88488 20.0766 5.1936C20.3769 4.50232 20.5215 3.75341 20.5 3C20.5 2.73478 20.3946 2.48043 20.2071 2.29289C20.0196 2.10536 19.7652 2 19.5 2C19.2348 2 18.9804 2.10536 18.7929 2.29289C18.6054 2.48043 18.5 2.73478 18.5 3C18.5 5.622 16.129 6.53 14.326 6.841C14.9915 5.66105 15.3921 4.35037 15.5 3C15.5 2.20435 15.1839 1.44129 14.6213 0.87868C14.0587 0.31607 13.2956 0 12.5 0C11.7044 0 10.9413 0.31607 10.3787 0.87868C9.81607 1.44129 9.5 2.20435 9.5 3C9.60791 4.35037 10.0085 5.66105 10.674 6.841C8.871 6.53 6.5 5.622 6.5 3C6.5 2.73478 6.39464 2.48043 6.20711 2.29289C6.01957 2.10536 5.76522 2 5.5 2C5.23478 2 4.98043 2.10536 4.79289 2.29289C4.60536 2.48043 4.5 2.73478 4.5 3C4.47851 3.75341 4.62307 4.50232 4.92344 5.1936C5.2238 5.88488 5.6726 6.5016 6.238 7H4.5C3.43913 7 2.42172 7.42143 1.67157 8.17157C0.921427 8.92172 0.5 9.93913 0.5 11L0.5 12C0.5 12.5304 0.710714 13.0391 1.08579 13.4142C1.46086 13.7893 1.96957 14 2.5 14V19C2.50159 20.3256 3.02888 21.5964 3.96622 22.5338C4.90356 23.4711 6.17441 23.9984 7.5 24H17.5C18.8256 23.9984 20.0964 23.4711 21.0338 22.5338C21.9711 21.5964 22.4984 20.3256 22.5 19V14C23.0304 14 23.5391 13.7893 23.9142 13.4142C24.2893 13.0391 24.5 12.5304 24.5 12V11C24.5 9.93913 24.0786 8.92172 23.3284 8.17157C22.5783 7.42143 21.5609 7 20.5 7ZM12.5 2C12.7652 2 13.0196 2.10536 13.2071 2.29289C13.3946 2.48043 13.5 2.73478 13.5 3C13.3793 4.06283 13.0387 5.08891 12.5 6.013C11.9613 5.08891 11.6207 4.06283 11.5 3C11.5 2.73478 11.6054 2.48043 11.7929 2.29289C11.9804 2.10536 12.2348 2 12.5 2ZM2.5 11C2.5 10.4696 2.71071 9.96086 3.08579 9.58579C3.46086 9.21071 3.96957 9 4.5 9H11.5V12H2.5V11ZM4.5 19V14H11.5V22H7.5C6.70435 22 5.94129 21.6839 5.37868 21.1213C4.81607 20.5587 4.5 19.7956 4.5 19ZM20.5 19C20.5 19.7956 20.1839 20.5587 19.6213 21.1213C19.0587 21.6839 18.2956 22 17.5 22H13.5V14H20.5V19ZM13.5 12V9H20.5C21.0304 9 21.5391 9.21071 21.9142 9.58579C22.2893 9.96086 22.5 10.4696 22.5 11V12H13.5Z"
        fill="rgba(255, 255, 255, 0.60)"
      />
    </svg>
  );
};

import { Box, Flex, Spinner, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Tag from "../../../../general/Tag";
import BeforeLogin from "./BeforeLogin";
import AfterLogin from "./AfterLogin";
import Comment from "./Comment";
import { breakpoint } from "../../../../breakpoint-config/Breakpoint";

const CommentCardContent = ({
  isLogin,
  onClick,
  data,
  user,
  setSortOrder,
  sortOrder,
  isLoading,
}: any) => {
  const [isWeb] = useMediaQuery(`(min-width: ${breakpoint})`);

  useEffect(() => {}, [data]);

  return (
    <Flex direction={"column"} gap={"12px"} width={"100%"} height={"100vh"}>
      {/* tags */}
      <Flex gap={"8px"} alignItems={"center"}>
        <Tag
          p={"4px 8px"}
          text={"LATEST"}
          onClick={() => setSortOrder("latest")}
          border={sortOrder === "latest" ? "none" : "1px solid #FFF"}
          bg={sortOrder === "latest" ? "#DD1B78" : "#3E414A"}
        />
        <Tag
          p={"4px 8px"}
          text={"TOP"}
          border={sortOrder === "top" ? "none" : "1px solid #FFF"}
          bg={sortOrder === "top" ? "#DD1B78" : "#3E414A"}
          onClick={() => setSortOrder("top")}
        />
      </Flex>

      <Flex direction={"column"} gap={"24px"} width={"100%"}>
        {" "}
        {/* this will show if use is not logged-in */}
        {!isLogin && isWeb && <BeforeLogin onClick={onClick} />}
        {isLogin && isWeb && <AfterLogin user={user} />}
        {/* comments */}
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <Flex
            direction={"column"}
            gap={"24px"}
            maxHeight={"560px"}
            width={"100%"}
            flex="1"
            overflowY="auto"
          >
            {data?.map((comment: any, index: number) => (
              <Comment key={index} comment={comment} />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default CommentCardContent;

import React from "react";
import CustomText from "../../text/customText";
import { Flex, useMediaQuery } from "@chakra-ui/react";

const Data = ({ title, stats, onClick, isActive }: any) => {
  const [isLessThan767] = useMediaQuery(`(max-width: 767px)`);
  return (
    <Flex
      direction={"column"}
      gap={"8px"}
      alignContent={"center"}
      justifyContent={"center"}
      cursor={"pointer"}
      onClick={onClick}
    >
      <CustomText
        align={"center"}
        text={title}
        fontFamily={"Montserrat"}
        size={isLessThan767 ? "12px" : "16px"}
        weight={300}
        h={isLessThan767 ? "12px" : "16px"}
        letterSpacing={"-0.5px"}
        color={"rgba(255, 255, 255, 0.67)"}
      />

      <CustomText
        align={"center"}
        text={stats}
        fontFamily={"Montserrat"}
        size={isLessThan767 ? "20px" : "24px"}
        weight={700}
        h={isLessThan767 ? "20px" : "24px"}
        letterSpacing={"-0.5px"}
        color={isActive ? "#DD1B78" : "#FFF"}
        textDecoration={"underline"}
      />
    </Flex>
  );
};

export default Data;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api.client";

/// COMMENT CRUD \\\
//getting comments by ProjectID
export const useGetComments = (projectId: string, sortOrder= "latest") =>
  useQuery({
    queryKey: ["useGetComments", projectId, sortOrder],
    queryFn: async () => {
      const response = await client(`project/getComments/${projectId}?limit=10&offset=0&sortOrder=${sortOrder}`);
      return response;
    },
  });

//creating comment
export const useCreateComment = () => {
  const queryClient = useQueryClient(); // Get the query client instance

  return useMutation<any, Error, any>({
    mutationFn: async (data:any) => {
      const response = await client("project/createComment", {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({ queryKey: ["useGetComments"] });
      }
      return response;
    },
  });
};

/// comment remove \\\\

export const useCommentDelete = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async () => {
      const response = await client(`project/deleteComment/${id}`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({ queryKey: ["useGetComments"] });
      }
      return response;
    },
  });
};

/// Update Comment \\\

export const useUpdateComment = (id: string) => {
  const queryClient = useQueryClient(); // Get the query client instance

  return useMutation<any, Error, any>({
    mutationFn: async (data:any) => {
      const response = await client(`project/editComment/${id}`, {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["useGetComments", data.projectId],
        });
      }
      return response;
    },
  });
};

/// COMMENT REACTION \\\
//comment like
export const useCommentLike = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (data:any) => {
      const response = await client("project/createCommentReaction", {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

//comment unlike
export const useCommentDislike = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (data:any) => {
      const response = await client("project/removeCommentReaction", {
        method: "POST",
        data: data,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};
